import { Result, Typography } from 'antd'
import { FallbackProps } from 'react-error-boundary'

const { Paragraph, Text } = Typography

export const ErrorFallback = (props: FallbackProps) => {
  return (
    <Result status='error' title='Erro!' subTitle={props.error.name}>
      <div className='desc'>
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            Segue o log abaixo:
          </Text>
        </Paragraph>
        <Paragraph>{props.error.message}</Paragraph>
        <Paragraph>{props.error.stack}</Paragraph>
      </div>
    </Result>
  )
}
