import {
  Box,
  HStack,
  Icon,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react'
import { format, parse } from 'date-fns'
import { FaCalendar, FaRegCalendarCheck } from 'react-icons/fa'
import { MdCheck, MdClose } from 'react-icons/md'
import { useSetRecoilState } from 'recoil'
import { ClienteRegistro, Empresa } from '../../../generated'
import { empresaSelecionadaState } from '../../../store/empresaSelecionadaState'
import { clienteSelecionadoState } from './CheckCliente'
import { ListaEmpresas } from './ListaEmpresas'

const DataSincronismoText = (props: { dataSincronismo: string }) => (
  <Box
    mt={{
      base: 2,
      md: 0,
    }}
    d='flex'
    alignItems='center'
  >
    <Icon as={FaCalendar} color='green.400' mr={1.5} />
    <Text
      color='gray.600'
      overflow='hidden'
      textOverflow='ellipsis'
      whiteSpace='nowrap'
      fontWeight='medium'
      mr={1.5}
    >
      Sincronizado em
    </Text>
    <Text>
      {format(
        parse(props.dataSincronismo, 'yyyyMMddHHmm', new Date()),
        'dd/MM/yyyy HH:mm'
      )}
    </Text>
  </Box>
)

const DataUltimoDownloadText = (props: { dataUltimoDownload: string }) => (
  <Box
    mt={{
      base: 2,
      md: 0,
    }}
    d='flex'
    alignItems='center'
  >
    <Icon as={FaRegCalendarCheck} mr={1.5} color='green.400' />
    <Text
      fontWeight='medium'
      overflow='hidden'
      textOverflow='ellipsis'
      whiteSpace='nowrap'
      mr={1.5}
      color='gray.600'
    >
      Ultimo download:
    </Text>
    <Box>{format(new Date(props.dataUltimoDownload), 'dd/MM/yyyy HH:mm')}</Box>
  </Box>
)

export const ItemListaCliente = (props: { cliente: ClienteRegistro }) => {
  const setCliente = useSetRecoilState(clienteSelecionadoState)
  const setEmpresa = useSetRecoilState(empresaSelecionadaState)

  const onSelecionar = (empresa: Empresa) => {
    setCliente(props.cliente)
    setEmpresa(empresa)
  }

  return (
    <Box px={[2, 4]} py={1}>
      <HStack justify='space-between'>
        <Text
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          fontWeight='medium'
          color='blue.500'
        >
          {props.cliente.cliente}
        </Text>
        <Tag
          variant='solid'
          rounded={4}
          colorScheme={props.cliente.configurado ? 'blue' : 'red'}
        >
          <TagLeftIcon as={props.cliente.configurado ? MdCheck : MdClose} />
          <TagLabel>
            Sincronismo{' '}
            {props.cliente.configurado ? 'configurado' : 'não configurado'}
          </TagLabel>
        </Tag>
      </HStack>
      <Box d={{ md: 'flex' }} justifyContent={{ md: 'space-between' }}>
        <Box d={{ md: 'flex' }}>
          <Text d='flex' alignItems='center' color='gray.500' fontSize='sm'>
            <Text as='span' mr={1.5} fontWeight='semibold'>
              Contrato:
            </Text>
            {props.cliente.codigo}
          </Text>
          <Text
            ml={{ base: 0, md: 4 }}
            d='flex'
            alignItems='center'
            color='gray.500'
          >
            <Text as='span' mr={1.5} fontWeight='semibold'>
              CNPJ:
            </Text>
            {props.cliente.cnpj}
          </Text>
        </Box>
        {props.cliente.dataSincronismo &&
          props.cliente.dataSincronismo.match(/^[0-9]*$/) && (
            <DataSincronismoText
              dataSincronismo={props.cliente.dataSincronismo}
            />
          )}
        {props.cliente.dataUltimoDownload && (
          <DataUltimoDownloadText
            dataUltimoDownload={props.cliente.dataUltimoDownload}
          />
        )}
      </Box>
      {props.cliente.configurado && (
        <Box mt={1}>
          <ListaEmpresas cliente={props.cliente} onSelecionar={onSelecionar} />
        </Box>
      )}
    </Box>
  )
}
