import { atom } from 'recoil'

export const sidebarState = atom({
  key: 'sidebar',
  default: true,
})

export const openFiltrosState = atom({
  key: 'openFiltros',
  default: false,
})

export const downloadSelecionadosModalState = atom({
  key: 'downloadSelecionadosModal',
  default: false,
})

export const downloadTodosModalState = atom({
  key: 'downloadTodosModal',
  default: false,
})
