import { Select } from 'antd'
import React from 'react'
import { useRecoilState } from 'recoil'
import { ModeloNotaRecord } from '../../lib/types'
import { filtroModeloState } from '../../store/filtrosState'

export const options = Object.keys(ModeloNotaRecord).map(key => ({
  label: ModeloNotaRecord[key],
  value: key,
}))

export const FiltroModelo = (props: { disabled?: boolean }) => {
  const [modelos, setModelos] = useRecoilState(filtroModeloState)

  return (
    <Select
      disabled={props.disabled}
      showArrow
      dropdownStyle={{ zIndex: 2000 }}
      showSearch={false}
      placeholder='Modelo'
      maxTagCount='responsive'
      maxTagTextLength={8}
      allowClear
      value={modelos || []}
      onChange={valor => setModelos(valor)}
      options={options}
      mode='multiple'
      className='w-32'
    />
  )
}
