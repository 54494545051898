import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { FaExclamationCircle } from 'react-icons/fa'
import { EnviarCodigoLoginDto } from '../../generated'
import { apiEnviarCodigoLogin } from '../../lib/api/apiContador'
import { handleError } from '../../lib/errorHandler'

export const EnviarCodigoLoginForm = (props: {
  onSucesso: (email: string) => void
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  const disclosure = useDisclosure()

  const enviarCodigoLogin = async (body: EnviarCodigoLoginDto) => {
    await apiEnviarCodigoLogin(body)
      .then(res => {
        toast.success('Um Código de acesso foi enviado para o seu email.')
        props.onSucesso(body.email)
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          disclosure.onOpen()
        } else {
          toast.error(handleError(err))
        }
      })
  }

  return (
    <Box>
      <Heading my={4} size='md'>
        Bem vindo contador, digite seu email
      </Heading>

      <form onSubmit={handleSubmit(enviarCodigoLogin)}>
        <FormControl isInvalid={errors.email}>
          <Input
            id='email'
            placeholder='Email'
            {...register('email', {
              required: 'Digite um email',
            })}
          />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>

        <Button
          colorScheme='blue'
          isFullWidth
          my={4}
          isLoading={isSubmitting}
          type='submit'
        >
          Prosseguir
        </Button>
      </form>
      <Modal size='2xl' isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader d='flex' alignItems='center'>
            <Icon as={FaExclamationCircle} color='red.500' mr={2} />
            Email não cadastrado
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align='flex-start'>
              <Text>
                O email informado não coincide com o de nenhum contador no nosso
                cadastro de contadores.
              </Text>
              <Text>
                Certifique-se que seus dados estejam atualizados junto a
                Success, caso necessário entre em contato com a Success, fale
                com nosso setor de atendimento e diga que deseja atualizar seu
                email no cadastro de contadores.
              </Text>
              <Text fontWeight='medium'>(38) 3671-5156</Text>
              <Text fontWeight='medium'>(38)98825-2024</Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={disclosure.onClose}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
