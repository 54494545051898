// N-Ativa; // P-Enviada; // V-Validada; // A-Autorizada; // I-Inutilizada;
// R-Rejeitada; // D-Denegada; // F-Encerrada; // C-Cancelada; // G-Contingência

export type StatusNota =
  | 'A'
  | 'I'
  | 'D'
  | 'C'
  | 'G'
  | 'N'
  | 'P'
  | 'V'
  | 'R'
  | 'F'
  | string

export const ModeloNotaRecord: Record<string, string> = {
  'nfe-emitida': 'NFe Emitidas',
  'nfe-terceiro': 'NFe Terceiros',
  nfce: 'NFCe',
  'cte-emitida': 'CTe Emitidas',
  'cte-terceiro': 'CTe Terceiros',
  mdfe: 'MDFe',
}

export type Empresa = {
  cnpj: string
  fantasia: string
  razao_social: string
}
