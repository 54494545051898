import { LockOutlined, UserOutlined } from '@ant-design/icons'
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Icon,
} from '@chakra-ui/react'
import { Form, Input } from 'antd'
import React, { useEffect } from 'react'
import { FaExclamationCircle } from 'react-icons/fa'
import { useAsyncFn } from 'react-use'
import { LoginDto } from '../../generated'
import { useContador } from '../../hooks/useContador'
import { ContadorInfo } from '../Contador/ContadorInfo'
import { useClienteEmpresa } from './CheckCliente/CheckCliente'
import { ContratoInfo } from './ContratoInfo'

export const Login = (props: {
  onEnviar: (body: LoginDto) => Promise<any>
}) => {
  //   const enviar = async (values: { usuario: string; senha: string }) =>
  //     await props.onEnviar(values.usuario, values.senha)

  const [form] = Form.useForm()

  const { cliente, empresa } = useClienteEmpresa()
  const { contador } = useContador()

  const [state, fetch] = useAsyncFn(props.onEnviar)

  useEffect(() => {
    if (!empresa || !cliente || !contador) return
    fetch({
      empresa: empresa.codigo,
      contrato: cliente.codigo,
      usuario: contador.usuario,
      senha: contador.senha,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const enviar = async (data: { usuario: string; senha: string }) => {
    if (!empresa || !cliente) return
    return fetch({ ...data, empresa: empresa.codigo, contrato: cliente.codigo })
  }

  // if (state.loading) return <Loading />

  return (
    <div className='flex  flex-col h-screen'>
      <ContadorInfo />
      <ContratoInfo />
      {state.error && (
        <div>
          <Alert
            status='error'
            variant='subtle'
            flexDirection='column'
            justifyContent='center'
            textAlign='center'
            padding={6}
          >
            <AlertTitle
              d='flex'
              alignItems='center'
              mt={4}
              mb={2}
              fontSize='lg'
            >
              <Icon color='red' mr={1.5} as={FaExclamationCircle} />
              Falha no login automático
            </AlertTitle>
            <AlertDescription textAlign='left' maxW='container.md'>
              Caro contador, o sistema tenta entrar automaticamente com o seu
              usuário e senha padrão do Portal Contábil, porém esse usuário e
              senha deve bater com o cadastrado no sistema Success de cada
              cliente seu. Por favor oriente seu cliente a cadastrar no sistema
              dele o mesmo usuário e senha que você configurou aqui. Dessa forma
              você não precisará digitar um usuário e senha diferente para cada
              cliente. Para acessar manualmente digite o usuário e senha que seu
              cliente cadastrou para você.
            </AlertDescription>
          </Alert>
        </div>
      )}
      <div className='p-2 w-full max-w-sm mx-auto mt-6'>
        <Form
          form={form}
          onFinish={enviar}
          onFinishFailed={e => console.log(e)}
        >
          <Form.Item
            name='usuario'
            rules={[{ required: true, message: 'Preencha o campo usuario!' }]}
          >
            <Input
              size='large'
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Usuario'
            />
          </Form.Item>
          <Form.Item
            name='senha'
            rules={[{ required: true, message: 'Senha em branco!' }]}
          >
            <Input.Password
              size='large'
              placeholder='Senha'
              prefix={<LockOutlined className='site-form-item-icon' />}
            />
          </Form.Item>
          <Form.Item>
            <Button
              colorScheme='blue'
              isLoading={state.loading}
              type='submit'
              isFullWidth
              my={2}
            >
              Entrar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
