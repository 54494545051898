import useSWR from 'swr'
import { LoginDto } from '../generated'
import { apiGetUser, apiLogin } from '../lib/api/api'
import { handleRequest } from '../lib/errorHandler'

export const useUsuario = (dontQuery = false) => {
  const { data, error, mutate, isValidating } = useSWR(
    dontQuery ? null : 'user',
    apiGetUser,
    {
      shouldRetryOnError: false,

      // revalidateOnFocus: false,
    }
  )

  const user = data?.data
  const loading = !data && !error

  const login = async (body: LoginDto) => {
    const res = await handleRequest(apiLogin(body), {
      success: 'Bem vindo!',
      loading: 'Fazendo login...',
    })
    if (res.data) {
      localStorage.setItem('token', res.data)
      await mutate()
    }
  }

  const logout = async () => {
    localStorage.removeItem('token')
    await mutate()
  }

  return { user, loading, error, logout, login, mutate, isValidating }
}
