import { Center, Image } from '@chakra-ui/react'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import React, { useState } from 'react'

export const FrontPage = () => {
  const [showAlert, setShowAlert] = useState(true);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className='h-full bg-white w-full flex items-center justify-center'>
      {showAlert && <AlertMessage onClose={handleCloseAlert} />}
      <Center h='full' bg='white' w='full'>
        <Image src='logofull.png' />
      </Center>
    </div>
  )
}
