import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
} from '@chakra-ui/react'
import { Badge, Checkbox, Drawer, Result, Skeleton, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { compareAsc, format } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { MdCheck } from 'react-icons/md'
import { useToggle } from 'react-use'
import { Documento } from '../generated'
import { usePendencias, usePendenciasByChaves } from '../hooks/usePendencias'
import { PendenciasResponse } from '../lib/api/apiDocumentos'
import { handleError } from '../lib/errorHandler'
import { stringToData } from '../lib/utils'
import { PendenciasLista } from './PendenciasLista'
import { TagModelo } from './Tabela/TagModelo'
import { TagStatus } from './Tabela/TagStatus'

export const VerificarPendenciasButton = () => {
  const [isResultadoOpen, setIsResultadoOpen] = useState(false)

  const { numeroPendencias, isLoading, error } = usePendencias()

  return (
    <Box mr={3}>
      <Badge overflowCount={9999} count={numeroPendencias}>
        <Button
          size='sm'
          isDisabled={false}
          colorScheme={
            isLoading || !!numeroPendencias || error ? 'red' : 'blue'
          }
          onClick={() => setIsResultadoOpen(true)}
          isLoading={isLoading}
          rightIcon={!numeroPendencias && !error ? <MdCheck /> : undefined}
        >
          Pendências
        </Button>
      </Badge>
      <Drawer
        placement='bottom'
        height='100%'
        title='Pendências'
        visible={isResultadoOpen}
        onClose={() => setIsResultadoOpen(false)}
      >
        <PendenciasContainer />
      </Drawer>
    </Box>
  )
}

export const PendenciasByChavesContainer = (props: {
  mostrarConcordar?: boolean
  onConcordar?: () => any
}) => {
  const {
    pendencias,
    numeroPendencias,
    isLoading,
    error,
  } = usePendenciasByChaves()

  return (
    <PendenciasVisualizacao
      pendencias={pendencias}
      error={error}
      numeroPendencias={numeroPendencias}
      isLoading={isLoading}
      onConcordar={props.onConcordar}
      mostrarConcordar={props.mostrarConcordar}
    />
  )
}

export const PendenciasModoContainer = ({
  modo,
  ...props
}: {
  modo: 'selecao' | 'query'
} & PendenciasContainerProps) => {
  return modo === 'query' ? (
    <PendenciasContainer {...props} />
  ) : (
    <PendenciasByChavesContainer {...props} />
  )
}

export type PendenciasContainerProps = {
  mostrarConcordar?: boolean
  onConcordar?: () => any
}

export const PendenciasContainer = (props: PendenciasContainerProps) => {
  const { pendencias, numeroPendencias, isLoading, error } = usePendencias()

  return (
    <PendenciasVisualizacao
      pendencias={pendencias}
      error={error}
      numeroPendencias={numeroPendencias}
      isLoading={isLoading}
      onConcordar={props.onConcordar}
      mostrarConcordar={props.mostrarConcordar}
    />
  )
}

export const TabelaDocsStatusInvalidos = (props: { docs: Documento[] }) => {
  const colunas: ColumnsType<Documento> = useMemo(
    () => [
      {
        title: 'Tipo',
        key: 'modelo',
        dataIndex: 'modelo',
        render: (value: string) => <TagModelo modelo={value} />,
        width: 100,
      },
      {
        title: 'Série',
        key: 'serie',
        dataIndex: 'serie',
        render: (value: number | undefined) =>
          value?.toString().padStart(3, '0'),
        sorter: (a, b) => 1,
        width: 80,
      },
      {
        title: 'Código',
        key: 'codigo',
        dataIndex: 'codigo',
        width: 100,
        sorter: (a, b) => Number(a.codigo) - Number(b.codigo),
        // sortDirections: ['descend'],
      },
      {
        title: 'Emissão',
        key: 'emissao',
        dataIndex: 'emissao',
        render: (value, record) => (
          <span className='break-normal'>
            {format(stringToData(value), 'dd/MM/yyyy')}
          </span>
        ),
        sorter: (a, b) =>
          compareAsc(stringToData(a.emissao), stringToData(b.emissao)),
        width: 112,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => 1,
        render: (value, record) => <TagStatus status={value} />,
        width: 112,
      },
    ],
    []
  )

  return (
    <Table
      size='small'
      rowKey='chave'
      dataSource={props.docs}
      columns={colunas}
    />
  )
}

export const PendenciasVisualizacao = (
  props: {
    error: any
    pendencias?: PendenciasResponse
    isLoading: boolean
    numeroPendencias: number
  } & PendenciasContainerProps
) => {
  const [concordou, toggleConcordou] = useToggle(false)

  if (props.error)
    return (
      <Space
        direction='vertical'
        size='large'
        align='center'
        className='w-full'
      >
        <Result
          status='warning'
          className='py-4'
          title='Não foi possível verificar as pendências'
          subTitle={handleError(props.error)}
        />
        {props.mostrarConcordar && (
          <>
            <Alert status='warning'>
              <AlertIcon />
              <AlertTitle>Aviso!</AlertTitle>
              <AlertDescription>
                Não foi possível verificar os documentos, não há garantia de que
                todos os documentos serão incluídos no download, prossiga por
                sua conta e risco.
              </AlertDescription>
            </Alert>
            <Checkbox
              checked={concordou}
              onChange={e => toggleConcordou(e.target.checked)}
            >
              Estou ciente
            </Checkbox>
            <Button
              onClick={props.onConcordar}
              colorScheme='blue'
              isDisabled={!concordou}
            >
              Prosseguir
            </Button>
          </>
        )}
      </Space>
    )

  if (props.isLoading || !props.pendencias)
    return <Skeleton active className='w-full' />

  return props.numeroPendencias > 0 ? (
    <>
      <PendenciasLista pendencias={props.pendencias} />
      {props.mostrarConcordar && (
        <Space
          direction='vertical'
          size='large'
          align='center'
          className='w-full mt-4'
        >
          <Alert status='warning'>
            <AlertIcon />
            <AlertTitle>Aviso!</AlertTitle>
            <AlertDescription>
              Os documentos com pendências ou XML inválidos não serão incluídos
              no download.
            </AlertDescription>
          </Alert>

          <Checkbox
            checked={concordou}
            onChange={e => toggleConcordou(e.target.checked)}
          >
            Estou ciente
          </Checkbox>
          <Button
            onClick={props.onConcordar}
            colorScheme='blue'
            isDisabled={!concordou}
          >
            Prosseguir
          </Button>
        </Space>
      )}
    </>
  ) : (
    <Space direction='vertical' align='center' className='w-full' size='large'>
      <Result
        status='success'
        title='Nenhuma pendência encontrada no período informado.'
      />
      {props.mostrarConcordar && (
        <Button onClick={props.onConcordar} colorScheme='blue'>
          Prosseguir
        </Button>
      )}
    </Space>
  )
}
