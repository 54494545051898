import { api } from 'lib/api/api'
import {
  Documento,
  GetByChavesDto,
  GetDocumentosDto,
  GetDocumentosResponse,
} from '../../generated'

export const apiGetTodosDocumentos = async (body: GetDocumentosDto) =>
  api.get<GetResponse<Documento>>('documento/get', { params: body })

export type PendenciasResponse = {
  saltos?: Array<{
    codigo: [string, string]
    modelo: string
    serie: string
  }>
  docsStatusInvalidos: Documento[]
  docsXmlAusente: Documento[]
}

export const apiVerificarPendencias = async (body: GetDocumentosDto) =>
  api.get<PendenciasResponse>('documento/pendencias', { params: body })

export const apiVerificarPendenciasByChaves = async (body: GetByChavesDto) =>
  api.get<PendenciasResponse>('documento/pendenciasByChaves', { params: body })

type GetResponse<T> = GetDocumentosResponse & {
  data: T[]
}
