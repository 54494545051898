import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Image,
  useColorModeValue,
} from '@chakra-ui/react'
import { CgPushLeft, CgPushRight } from 'react-icons/cg'
import { useRecoilState } from 'recoil'
import { SuccessLogoNome } from '../Logo/SuccessLogo'
import logosimbolo from './logosimbolo.png'
import { NavItem } from './NavItem'
import { sidebarState, routes } from './AppShell'

export const SidebarContent = (props: BoxProps) => {
  const [isCollapsed, setCollapse] = useRecoilState(sidebarState)

  return (
    <Box
      as='nav'
      pos='fixed'
      top='0'
      left='0'
      // zIndex='sticky'
      h='full'
      pb='2'
      overflowX='hidden'
      overflowY='auto'
      bg='blue.100'
      borderColor={useColorModeValue('inherit', 'gray.700')}
      borderRightWidth='1px'
      transition='.3s ease'
      d='flex'
      flexDir='column'
      justifyContent='stretch'
      w={isCollapsed ? '14' : '52'}
      {...props}
    >
      <Flex px={isCollapsed ? 3 : 4} py={isCollapsed ? 3 : 5} align='center'>
        {isCollapsed ? <Image src={logosimbolo} /> : <SuccessLogoNome />}
      </Flex>
      <Flex
        direction='column'
        as='nav'
        fontSize='sm'
        flex={1}
        color='gray.600'
        aria-label='Navegação'
      >
        {routes.map(rota => (
          <NavItem key={rota.path} {...rota} />
        ))}
        <Box flex={1} />
        <IconButton
          display={{ base: 'none', md: '-webkit-box' }}
          aria-label='collapse'
          variant='ghost'
          icon={isCollapsed ? <CgPushRight /> : <CgPushLeft />}
          onClick={() => setCollapse(!isCollapsed)}
        />
      </Flex>
    </Box>
  )
}
