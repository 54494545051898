import { Box } from '@chakra-ui/react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { memo, useCallback, useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Documento } from '../../generated'
import { useTabela } from '../../hooks/useTabela'
import { docKey, selecaoKeyState, selecaoState } from '../../store/selecaoState'

type Props<T> = {
  data?: T[]
  isLoading: boolean
  colunasExtra?: ColumnsType<T>
}

export const TabelaDocumentos = memo(<T extends Documento>(props: Props<T>) => {
  const { handleTableChange, colunasFiltradas, paginacao } = useTabela()

  const setSelecao = useSetRecoilState(selecaoState)
  const selecaoKeys = useRecoilValue(selecaoKeyState)

  // const { size, scrollWidth } = useResponsivo()
  // const fullWidth = size.width - (scrollWidth || 0)

  const onChangeSelection = useCallback((keys, rows) => setSelecao(rows), [
    setSelecao,
  ])

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selecaoKeys,
      onChange: onChangeSelection,
    }),
    [selecaoKeys, onChangeSelection]
  )

  return (
    // <Profiler
    //   id='table'
    //   onRender={(id, phase, actualTime, baseTime, startTime, commitTime) => {
    //     console.log(`${id}'s ${phase} phase:`)
    //     console.log(`Actual time: ${actualTime}`)
    //     console.log(`Base time: ${baseTime}`)
    //     console.log(`Start time: ${startTime}`)
    //     console.log(`Commit time: ${commitTime}`)
    //   }}
    // >
    <Box flex={1}>
      <Table
        scroll={{ x: '70vw', y: 450 }}
        size='small'
        tableLayout='fixed'
        columns={colunasFiltradas}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '100', '200'],
          ...paginacao,
        }}
        loading={props.isLoading}
        rowKey={docKey}
        rowSelection={rowSelection}
        onChange={handleTableChange}
        dataSource={props.data}
      />
    </Box>
    // </Profiler>
  )
})
