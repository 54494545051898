import useSWR from 'swr'
import { apiGetClientes } from '../lib/api/apiContador'

export const useClientes = () => {
  const { data, error } = useSWR('contador/clientes', apiGetClientes)

  const clientes = data && data.data
  const loading = !clientes && !error

  return { clientes, error, loading }
}
