import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { mutate } from 'swr'
import { ClienteRegistro } from '../../../generated'
import { empresaSelecionadaState } from '../../../store/empresaSelecionadaState'
import { AlterarUsuarioButton } from '../../AlterarUsuarioButton'
import { ContadorInfo } from '../../Contador/ContadorInfo'
import { ListaClientes } from './ListaClientes'

export const clienteSelecionadoState = atom<ClienteRegistro | null>({
  key: 'clienteSelecionado',
  default: null,
})

export const useClienteEmpresa = () => {
  const [cliente, setCliente] = useRecoilState(clienteSelecionadoState)
  const [empresa, setEmpresa] = useRecoilState(empresaSelecionadaState)

  const trocar = () => {
    setEmpresa(null)
    setCliente(null)
    if (!!localStorage.getItem('token')) {
      localStorage.removeItem('token')
      mutate('user')
    }
  }

  return { cliente, empresa, trocar }
}

export const CheckCliente = (props: { children: ReactNode }) => {
  const cliente = useRecoilValue(clienteSelecionadoState)
  const empresa = useRecoilValue(empresaSelecionadaState)

  if (!cliente || !empresa)
    return (
      <Box d='flex' bg='gray.100' flexDir='column' minH='100vh' h='full'>
        <ContadorInfo />
        <HStack px={[1, 2, 3]} mt={2} align='center' justify='flex-end'>
          <Box>
            <Menu>
              <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                Opções
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <AlterarUsuarioButton />
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </HStack>
        <ListaClientes />
      </Box>
    )

  return <>{props.children}</>
}
