import React from 'react';
import { Button, Modal } from 'antd';

interface XmlData {
    chave: string;
    codigo: string;
    data: any;
    datlan: string;
    downStatus: string | null;
    emissao: string;
    modelo: string;
    serie: number;
    sql_rowid: number;
    status: string;
    subSerie: any;
    valor: number | null | undefined;
}

interface Props {
    visible: boolean;
    onClose: () => void;
    xmlData: any;
}

const XmlErrorInvalido: React.FC<Props> = ({ visible, onClose, xmlData }) => {

    const errorXml = xmlData.find((xml: XmlData) => (xml.valor === null || xml.valor === undefined || xml.chave === undefined || xml.chave === null) && xml.status !== 'I');

    return (
        <Modal
            title="Erro ao Processar Documento"
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="ok" type="primary" onClick={onClose}>
                    OK
                </Button>
            ]}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src="https://www.svgrepo.com/show/111218/error.svg" alt="Erro" style={{ width: '70px', marginBottom: '20px' }} />
                <p>
                    Houve um problema ao processar um dos documentos XML.
                    <br />
                    O documento de código: <b>{errorXml?.codigo}</b> está incompleto, o que impede sua visualização.
                    <br />
                    <br />
                    Por favor, entre em contato com o cliente para verificação.
                </p>
            </div>
        </Modal>
    );
};

export default XmlErrorInvalido;