import { Button, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

export const NotFound = () => {
  return (
    <Result
      status='404'
      title='404'
      subTitle='Desculpe, parece que essa página não existe.'
      extra={
        <Button type='primary'>
          <Link to='/'>Voltar pro inicio</Link>
        </Button>
      }
    />
  )
}
