import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
} from '@chakra-ui/react'
import { MudarUsuarioSenhaForm } from './Contador/CheckUsuarioPadraoContador'

export const AlterarUsuarioButton = () => {
  const state = useDisclosure()

  return (
    <Box onClick={state.onOpen}>
      <Text>Alterar usuário / senha padrão</Text>
      <Modal size='3xl' isOpen={state.isOpen} onClose={state.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Alterar usuário / senha padrão</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <MudarUsuarioSenhaForm onFinish={state.onClose} />
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={state.onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
