import {
  Alert,
  AlertDescription,
  Text,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
} from '@chakra-ui/react'
import { ClienteRegistro, Empresa } from '../../../generated'
import { useEmpresas } from '../../../hooks/useEmpresas'
import { Loading } from '../Loading'

export const ListaEmpresas = (props: {
  cliente: ClienteRegistro
  onSelecionar: (empresa: Empresa) => any
}) => {
  const { empresas, isLoading, errorMessage } = useEmpresas(props.cliente)

  if (isLoading) return <Loading className='flex h-full' />

  if (errorMessage)
    return (
      <Alert py={2} fontSize='md' status='error'>
        <AlertIcon />
        <AlertTitle mr={3}>Erro</AlertTitle>
        <AlertDescription>{errorMessage}</AlertDescription>
      </Alert>
    )

  return (
    <Box>
      {empresas?.map(emp => (
        <EmpresaItem
          key={`${emp.codigo}`}
          empresa={emp}
          onSelecionar={props.onSelecionar}
        />
      ))}
    </Box>
  )
}

export const EmpresaItem = (props: {
  empresa: Empresa
  onSelecionar: (e: Empresa) => void
}) => {
  const { empresa, onSelecionar } = props
  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      px='2'
      my='1'
      cursor='pointer'
      onClick={() => onSelecionar(empresa)}
      _hover={{
        shadow: 'sm',
        boxShadow: 'outline',
      }}
    >
      
      <Box minWidth='250px' fontWeight='medium' color='blue.400'>
        <Text as='span' color='gray.900' mr={2} flex={0} fontWeight='bold'>
          {empresa.codigo}
        </Text>
        {empresa.empresa}
      </Box>
      <Box fontWeight='medium'>
        <Text as='span' color='gray.500' fontWeight='normal'>
          CNPJ:
        </Text>{' '}
        {empresa.cnpj}
      </Box>
    </Flex>
  )
}
