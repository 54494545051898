import { Box, BoxProps, Image } from '@chakra-ui/react'
import logo from './logosuc.png'
import logoNome from './logonomesuc.png'

export const SuccessLogo = (props: { className?: string }) => (
  <div className={props.className || 'mx-auto max-w-md'}>
    <Image src={logo} />
  </div>
)

export const SuccessLogoNome = (props: BoxProps) => (
  <Box mx='auto' maxW='md' {...props}>
    <Image src={logoNome} />
  </Box>
)
