import toast from 'react-hot-toast/dist/index'
import { ToastBar, Toaster } from 'react-hot-toast/dist/index'
import { Toast } from 'react-hot-toast/dist/core/types'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

export const CustomToaster = () => (
  <Toaster
    gutter={28}
    toastOptions={{
      className: 'max-w-md',
    }}
    position='top-right'
  >
    {(t: Toast) => (
      <ToastBar toast={t}>
        {({ icon, message }) => (
          <>
            {icon}
            {message}
            {t.type !== 'loading' && (
              <div className='flex items-start h-full'>
                <Button
                  onClick={() => toast.dismiss(t.id)}
                  icon={<CloseOutlined />}
                  type='link'
                  danger
                  shape='circle'
                />
              </div>
            )}
          </>
        )}
      </ToastBar>
    )}
  </Toaster>
)
