import { Box, Button, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { FaUser } from 'react-icons/fa'
import { useClienteEmpresa } from './CheckCliente/CheckCliente'

export const ContratoInfo = () => {
  const { cliente, empresa, trocar } = useClienteEmpresa()

  return (
    <>
      <Box
        p={3}
        d={{ sm: 'flex' }}
        alignItems={{ sm: 'center' }}
        justifyContent={{ sm: 'space-between' }}
      >
        <Box flex={1} minW={0}>
          <Heading
            size='lg'
            fontWeight='bold'
            lineHeight='taller'
            textOverflow={{ sm: 'ellipsis' }}
            overflow='hidden'
            whiteSpace='nowrap'
          >
            {cliente?.cliente}
          </Heading>
          <Stack
            mt={{ base: 1, sm: 0 }}
            direction={{ base: 'column', sm: 'row' }}
            wrap={{ sm: 'wrap' }}
          >
            <Flex mt={0} alignItems='center' fontSize='sm' color='gray.800'>
              <Text mr={1.5} color='gray.500'>
                Contrato:
              </Text>
              {cliente?.codigo}
            </Flex>

            <Flex mt={2} alignItems='center' fontSize='sm' color='gray.800'>
              <Text mr={1.5} color='gray.500'>
                CNPJ:
              </Text>
              {cliente?.cnpj}
            </Flex>
            <Flex mt={2} alignItems='center' fontSize='sm' color='gray.800'>
              <Text mr={1.5} color='gray.500'>
                Empresa:
              </Text>
              {empresa?.empresa}
            </Flex>
          </Stack>
        </Box>
        <div className='mt-5 flex lg:mt-0 lg:ml-4'>
          <Button onClick={trocar} leftIcon={<FaUser />}>
            Trocar cliente
          </Button>
        </div>
      </Box>
    </>
  )
}
