import { useCallback, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { GetDocumentosResponse } from '../generated'
import {
  filtroChaveState,
  filtroCodigoState,
  filtroIgnorarBaixadosState,
  filtroModeloState,
  filtroSerieState,
  filtroStatusState,
  periodoStringState,
} from '../store/filtrosState'
import { paginacaoState, sortState } from '../store/paginacaoState'

export const useFiltros = () => {
  const { inicio: dataInicio, fim: dataFim } = useRecoilValue(
    periodoStringState
  )

  const status = useRecoilValue(filtroStatusState)
  const serie = useRecoilValue(filtroSerieState)
  const codigo = useRecoilValue(filtroCodigoState)
  const chave = useRecoilValue(filtroChaveState)
  const sort = useRecoilValue(sortState)

  const ignorarBaixados = useRecoilValue(filtroIgnorarBaixadosState)

  const modelo = useRecoilValue(filtroModeloState)

  const { paginacao } = usePaginacao()
  const { current: pagina, pageSize: limit } = paginacao

  const coluna = sort?.coluna ?? undefined
  const sortOrder: string = sort?.desc ? 'desc' : 'asc'

  const filtros = useMemo(
    () => ({
      dataInicio,
      dataFim,
      pagina,
      limit,
      status,
      serie,
      codigo,
      chave,
      coluna,
      sortOrder,
      modelo,
      ignorarBaixados,
    }),
    [
      dataInicio,
      dataFim,
      pagina,
      limit,
      status,
      serie,
      codigo,
      chave,
      coluna,
      sortOrder,
      modelo,
      ignorarBaixados,
    ]
  )

  return filtros
}

export const usePaginacao = (docs?: GetDocumentosResponse) => {
  const [paginacao, setPaginacao] = useRecoilState(paginacaoState)
  const setTotal = useCallback(
    (tot: number) => setPaginacao(old => ({ ...old, total: tot })),
    [setPaginacao]
  )

  return { setTotal, paginacao, setPaginacao }
}
