import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { memo, useMemo } from 'react'
import { FaCheckCircle, FaTimes } from 'react-icons/fa'

export const BaixadoEmButton = memo((props: { data: string | null }) => {
  const { data } = props

  const horario = useMemo(() => {
    if (!data) return null

    const dd = new Date(data)
    return format(dd, 'dd/MM/yy HH:mm')
  }, [data])

  return data ? (
    <Tooltip label={`Baixado em ${horario}`}>
      <Flex align='center' justify='space-between'>
        <Text color='blue.400' fontWeight='semibold'>
          {horario}
        </Text>
        <Icon color='blue.400' as={FaCheckCircle} />
      </Flex>
    </Tooltip>
  ) : (
    <Tooltip label='Não Baixado'>
      <Flex align='center' justify='space-between'>
        <Text fontWeight='semibold' color='red.600'>
          Não Baixado
        </Text>
        <Icon as={FaTimes} color='red.600' />
      </Flex>
    </Tooltip>
  )
})
