// N-Ativa; // P-Enviada; // V-Validada; // A-Autorizada; // I-Inutilizada;
// R-Rejeitada; // D-Denegada; // F-Encerrada; // C-Cancelada; // G-Contingência

import { Tag } from 'antd'
import React, { memo } from 'react'
import { StatusNota } from '../../lib/types'

const coresStatus: Record<StatusNota, string> = {
  N: 'blue',
  P: 'purple',
  V: 'teal',
  A: '#87d068',
  I: 'gray',
  R: 'red',
  D: 'pink',
  F: 'cyan',
  C: '#710000',
  G: '#f50',
  null: '#F46036',
}

export const StatusMap: Record<StatusNota, string> = {
  N: 'Ativa',
  P: 'Enviada',
  V: 'Validada',
  A: 'Autorizada',
  I: 'Inutilizada',
  R: 'Rejeitada',
  D: 'Denegada',
  F: 'Encerrada',
  C: 'Cancelada',
  G: 'Contingencia',
  null: 'Pendente',
}

export const getLabelFromStatus = (s?: string | null) =>
  s ? StatusMap[s as StatusNota] : 'Sem status'

export const TagStatus = memo(({ status = 'null' }: { status: string }) => {
  const color = coresStatus[status as StatusNota]

  return <Tag color={color}>{StatusMap[status as StatusNota]}</Tag>
})
