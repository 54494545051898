import axios, { AxiosError } from 'axios'
import {
  DownloadDocumentosByQueryDto,
  EmailXmlPorChavesDto,
  Empresa,
  GetByChavesDto,
  GetDocumentosDto,
  LoginDto,
} from '../../generated'
import { UsuarioData } from '../../generated/models/UsuarioData'

export const apiEndpoint =
  process.env.REACT_APP_API_URL || 'http://localhost:4000'

export const api = axios.create({
  baseURL: apiEndpoint,
})

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    const contrato = localStorage.getItem('contrato')
    const contadorToken = localStorage.getItem('contador-token')

    if (contadorToken) {
      config.headers['contador-jwt'] = contadorToken
    }

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    if (contrato) {
      config.headers['contrato'] = contrato
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  (error: AxiosError) => {
    Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.request && error.request.responseType === 'blob' &&
      error.response && error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result as string)
          resolve(Promise.reject(error))
        }

        reader.onerror = () => {
          reject(error)
        }

        reader.readAsText(error.response.data)
      })
    }

    return Promise.reject(error)
  }
)

export const apiGetEmpresas = async (contrato: string) =>
  await api.get<Array<Empresa>>('contrato/empresas', { params: { contrato } })

export const apiUmXml = async ({
  chave,
  modelo,
}: {
  chave: string
  modelo: string
}) => {
  return await api.get('arquivo/xml', {
    params: { chave, modelo },

    responseType: 'blob',
  })
}

export const apiVariosXmls = async ({
  chaves,
  modelo,
}: {
  chaves: string[]
  modelo: any
}) => {
  // Implemente a lógica necessária para lidar com várias chaves
  const requests = chaves.map(chave => apiUmXml({ chave, modelo }));
  const responses = await Promise.all(requests);
  
  // Retorne as respostas
  return responses;
}

export const apiSelecaoXmlDownload = async (body: GetByChavesDto) => {
  return await api.post('arquivo/xml/chaves', body, {
    responseType: 'blob',
  })
}

export const apiSelecaoXmlEmail = async (body: EmailXmlPorChavesDto) => {
  return await api.post('arquivo/xml/email/chaves', body)
}

export const apiQueryXmlDownload = async (
  body: DownloadDocumentosByQueryDto
) => {
  return await api.post('arquivo/xml/query', body, {
    responseType: 'blob',
  })
}

export const apiQueryXmlDownloadComStatus = async (
  body: DownloadDocumentosByQueryDto
) => {
  return await api.post('arquivo/xml/query?download=true', body, {
    responseType:'text',
  })
}

export const apiQueryXmlEmail = async ({
  body,
  email,
}: {
  body: GetDocumentosDto
  email: string
}) => {
  return await api.post('arquivo/xml/email/query', {
    filtros: body,
    email,
  })
}

export const apiLogin = async (body: LoginDto) =>
  api.post<string>('login', body)

export const apiVerificaContrato = async (body: { codigo: string }) =>
  api.post<{ codigo: string; status: string }>('contrato/verificar', body)

export const apiPesquisacontrato = async (body: { nomeOuCnpj: string }) =>
  api.post<
    Array<{ codigo: string; cliente: string; status: string; cnpj: string }>
  >('contrato/pesquisar', body)

export const apiGetUser = async () => {
  try {
    const res = await api.get<UsuarioData>('user')
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
