import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Wrap,
} from '@chakra-ui/react'
import React from 'react'
import { FaFilter } from 'react-icons/fa'
import { useToggle } from 'react-use'
import { ResponsiveButton } from '../ResponsiveButton'
import { FiltroChave } from './FiltroChave'
import { FiltroCodigo } from './FiltroCodigo'
import { FiltroIgnorarBaixados } from './FiltroIgnorarBaixados'
import { FiltroModelo } from './FiltroModelo'
import { FiltroSerie } from './FiltroSerie'
import { FiltroStatus } from './FiltroStatus'
import { SeletorMesPeriodo } from './SeletorPeriodo'

export const FiltrosMobile = (props: {
  filtroTipo?: boolean
  filtroTipoDisabled?: boolean
}) => {
  const [isOpen, toggle] = useToggle(false)
  return (
    <div>
      <ResponsiveButton
        onClick={() => toggle(true)}
        size='sm'
        colorScheme='blue'
        icon={FaFilter}
      >
        Filtros
      </ResponsiveButton>
      <Drawer isOpen={isOpen} placement='right' onClose={() => toggle(false)}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Filtros</DrawerHeader>

          <DrawerBody zIndex='popover'>
            <Wrap spacing={6} shouldWrapChildren>
              <SeletorMesPeriodo />
              <FiltroStatus />
              {props.filtroTipo && (
                <FiltroModelo disabled={props.filtroTipoDisabled} />
              )}
              <FiltroSerie />
              <FiltroCodigo />
              <FiltroChave />
              <FiltroIgnorarBaixados />
            </Wrap>
          </DrawerBody>

          <DrawerFooter>
            <Button colorScheme='blue' mr={3} onClick={() => toggle(false)}>
              Fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  )
}
