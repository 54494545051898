import { Button } from '@chakra-ui/react'
import { Result } from 'antd'
import { FC } from 'react'
import { FaRedo } from 'react-icons/fa'
import { useContador } from '../../hooks/useContador'
import { Loading } from '../Auth/Loading'
import { ContadorLogin } from './ContadorLogin'

export const CheckContador: FC = ({ children }) => {
  const {
    contador,
    error,
    loading,
    login,
    isValidating,
    mutate,
  } = useContador()

  if (loading) return <Loading />

  if (error && !error.response)
    return (
      <Result
        status='error'
        title='Sem conexão.'
        subTitle='Nao conseguimos conectar ao servidor'
        extra={[
          <Button
            key='1'
            isLoading={isValidating}
            onClick={() => mutate()}
            colorScheme='blue'
            leftIcon={<FaRedo />}
          >
            Tentar Novamente
          </Button>,
        ]}
      />
    )

  if (contador) return <>{children}</>

  return <ContadorLogin onEnviar={login} />
}
