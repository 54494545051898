import { Select } from 'antd'
import React from 'react'
import { useRecoilState } from 'recoil'
import { filtroStatusState } from '../../store/filtrosState'
import { StatusMap } from '../Tabela/TagStatus'

export const options = Object.keys(StatusMap).map(key => ({
  label: StatusMap[key],
  value: key,
}))

export const FiltroStatus = () => {
  const [status, setStatus] = useRecoilState(filtroStatusState)

  const onChange = (value: string[]) => {
    setStatus(value)
  }

  return (
    <Select
      showArrow
      dropdownStyle={{ zIndex: 2000 }}
      showSearch={false}
      placeholder='Status'
      allowClear
      maxTagCount='responsive'
      maxTagTextLength={7}
      value={status || []}
      onChange={onChange}
      options={options}
      mode='multiple'
      className='w-24 sm:w-32'
    />
  )
}
