import { Input } from 'antd'
import React from 'react'
import { useSetRecoilState } from 'recoil'
import { filtroChaveState } from '../../store/filtrosState'

export const FiltroChave = () => {
  const setChave = useSetRecoilState(filtroChaveState)

  const onSearch = (value: string) => setChave(value)

  return (
    <div className='w-50'>
      <Input.Search
        placeholder='Chave'
        allowClear
        onSearch={onSearch}
        className=''
      />
    </div>
  )
}
