import { Button, HStack, Tag } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { useResponsivo } from '../../hooks/useResponsivo'
import { useUsuario } from '../../hooks/useUsuario'
import { empresaSelecionadaState } from '../../store/empresaSelecionadaState'
import { useClienteEmpresa } from '../Auth/CheckCliente/CheckCliente'

const TagEmpresa = () => {
  const empresa = useRecoilValue(empresaSelecionadaState)

  const { sm } = useResponsivo()
  return sm ? (
    <div className='inline'>
      <Tag size='sm' colorScheme='blue' variant='solid' rounded={3}>
        {empresa?.empresa}
      </Tag>
    </div>
  ) : null
}

const TagCNPJ = () => {
  const empresa = useRecoilValue(empresaSelecionadaState)
  return (
    <div className='inline'>
      CNPJ:
      <Tag size='sm' mx={2} colorScheme='blue' variant='solid' rounded={3}>
        {empresa?.cnpj}
      </Tag>
    </div>
  )
}

const TagUsuario = () => {
  const { user } = useUsuario()
  const { lg } = useResponsivo()
  return lg ? (
    <div className='inline'>
      Usuário:
      <Tag size='sm' mx={2} colorScheme='blue' variant='solid' rounded={3}>
        {user?.usuario}
      </Tag>
    </div>
  ) : null
}

export const ExtraElements = () => (
  <HStack spacing={5}>
    <TagEmpresa key='0' />
    <TagCNPJ key='1' />
    <TagUsuario key='2' />
    <BotaoSair key='3' />
  </HStack>
)

export const BotaoSair = () => {
  const { trocar } = useClienteEmpresa()
  const { logout } = useUsuario()

  const sair = async () => {
    trocar()
    await logout()
  }

  return (
    <Button size='xs' colorScheme='red' onClick={sair}>
      Sair
    </Button>
  )
}
