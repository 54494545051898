import { FormControl, FormLabel, Switch } from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { filtroIgnorarBaixadosState } from '../../store/filtrosState'

export const FiltroIgnorarBaixados = () => {
  const [value, setValue] = useRecoilState(filtroIgnorarBaixadosState)

  return (
    <FormControl display='flex' alignItems='center'>
      <FormLabel htmlFor='ignorar-baixados' mb='0'>
        Ignorar Baixados?
      </FormLabel>
      <Switch
        id='ignorar-baixados'
        isChecked={value}
        onChange={e => setValue(e.target.checked)}
      />
    </FormControl>
  )
}
