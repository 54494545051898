import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox } from 'antd';
import { Image } from '@chakra-ui/react';

interface AlertMessageProps {
  onClose: () => void;
}

const AlertMessage: React.FC<AlertMessageProps> = ({ onClose }) => {
  const [hideMessage, setHideMessage] = useState(false);

  useEffect(() => {
    // Verifica se a mensagem já foi escondida anteriormente
    const isMessageHidden = localStorage.getItem('hideAlertMessage');
    if (isMessageHidden === 'true') {
      onClose();
    }
  }, [onClose]);

  const handleCheckboxChange = (e: any) => {
    setHideMessage(e.target.checked);
  };

  const handleConfirm = () => {
    if (hideMessage) {
      // Salva o estado de "Não mostrar essa mensagem novamente" no localStorage
      localStorage.setItem('hideAlertMessage', 'true');
    }
    onClose();
  };

  return (
    <Modal
      title="Novo Recurso Disponível!"
      visible={true}
      onCancel={onClose}
      width={600}
      centered={true}
      footer={[
        <Button key="confirm" type="primary" onClick={handleConfirm}>
          Confirmar
        </Button>,
      ]}
    >
      <div style={{ textAlign: 'justify' }}>
        <p>
          Olá Contador,
          <br />
          Para melhorar a sua experiência com o nosso Portal Contábil, desenvolvemos uma melhoria que irá trazer a praticidade de <b>Visualizar o DANFE dos XML</b> de seus clientes e ainda realizar o <b>Download</b> deste arquivo de forma fácil e rápida.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Image style={{ marginBottom: '5px', marginTop: '5px' }} src='acoes.png' alt='Exemplo de Ações' />
          <br />
          <p>
            Acesse de forma simples:
            <br />
            Na aba de Documentos Emitidos, seja NFe Própria ou de Terceiros, na coluna <b>"Ações"</b>,
            <br />
            selecione <b>"Visualizar DANFE"</b> ou <b>"Fazer Download do DANFE"</b>.
            Também é possível realizar o <b>Download de vários DANFE's</b> de uma só vez. <b>Selecione</b> quais documentos deseja gerar o DANFE, <b>clique</b> no botão Download e <b>selecione</b> uma das formas desejadas.
            <br />
          </p>
          <br />
          <Image style={{ marginTop: '8px' }} src='downtd.png' alt='Exemplo de Download' />
        </div>
        <br />
        <p>
          Aprimore sua experiência conosco!
        </p>
        <br />
      </div>
      <Checkbox onChange={handleCheckboxChange}>Não mostrar essa mensagem novamente</Checkbox>
    </Modal>
  );
};

export default AlertMessage;
