import { useMedia, useWindowSize, useScrollbarWidth } from 'react-use'

export const useResponsivo = () => {
  const sm = useMedia('(min-width: 640px)')
  const md = useMedia('(min-width: 768px)')
  const lg = useMedia('(min-width: 1024px)')
  const xl = useMedia('(min-width: 1280px)')
  const size = useWindowSize()
  const scrollWidth = useScrollbarWidth()

  return { sm, md, lg, xl, size, scrollWidth: scrollWidth || 0 }
}
