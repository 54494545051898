import { AxiosError, AxiosResponse } from 'axios'
import toast from 'react-hot-toast/dist/index'
import { ToastOptions } from 'react-hot-toast'

export const handleError = (error: AxiosError): string => {
  // console.log(error.toJSON())
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data)
    // console.log('object :>> ', error.response)
    // console.log(error.response.status)
    // console.log(error.response.headers)
    return error.response.data.message
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // const req: XMLHttpRequest = error.request
    // console.log(req)
    return error.message
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('Error', error.message)
    return error.message
  }
}

export const handleRequest = async (
  req: Promise<AxiosResponse>,
  { success, loading }: { success: string; loading: string },
  opts?: ToastOptions
) => {
  return toast.promise(
    req,
    {
      success,
      loading,
      error: error => handleError(error),
    },
    {
      loading: {
        duration: 120000,
      },
      error: {
        duration: 8000,
      },
      duration: 5000,
      ...opts,
    }
  )
}

export const handlePromise = async (
  promise: Promise<any>,
  { success, loading }: { success: string; loading: string },
  opts?: ToastOptions
) => {
  return toast.promise(
    promise.then(() => Promise.resolve({ data: null })), // Resolve para um objeto semelhante a AxiosResponse
    {
      success,
      loading,
      error: (error) => handleError(error),
    },
    {
      loading: {
        duration: 120000,
      },
      error: {
        duration: 8000,
      },
      duration: 5000,
      ...opts,
    }
  );
};
