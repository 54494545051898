import { atom, selector } from 'recoil'
import { Documento } from '../generated'

export const selecaoState = atom<Documento[]>({
  key: 'selecao',
  default: [],
})

export const docKey = (doc: Documento) =>
  `${doc.modelo}-${doc.sql_rowid}-${doc.codigo}-${doc.chave}`

export const selecaoChaveState = selector({
  key: 'selecaoChaves',
  get: ({ get }) => {
    const selecao = get(selecaoState)
    return selecao.map(r => r.chave)
  },
})

export const selecaoKeyState = selector({
  key: 'selecaoKeyState',
  get: ({ get }) => {
    const selecao = get(selecaoState)
    return selecao.map(docKey)
  },
})
