import { Input } from 'antd'
import { useSetRecoilState } from 'recoil'
import { filtroCodigoState } from '../../store/filtrosState'

export const FiltroCodigo = () => {
  const setCodigo = useSetRecoilState(filtroCodigoState)

  const onSearch = (value: string) => setCodigo(value)

  return (
    <div className='w-40'>
      <Input.Search
        placeholder='Codigo'
        allowClear
        onSearch={onSearch}
        className=''
      />
    </div>
  )
}
