import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { useForm } from 'react-hook-form'
import { useContador } from '../../hooks/useContador'
import { apiAlterarSenha } from '../../lib/api/apiContador'
import { handleRequest } from '../../lib/errorHandler'
import { ContadorInfo } from './ContadorInfo'

export const CheckUsuarioPadraoContador = (props: { children: ReactNode }) => {
  const { contador } = useContador()

  if (!contador?.usuario || !contador.senha)
    return (
      <Box>
        <ContadorInfo />
        <MudarUsuarioSenhaForm />
      </Box>
    )

  return <>{props.children}</>
}

export const MudarUsuarioSenhaForm = (props: { onFinish?: () => void }) => {
  const { mutate } = useContador()
  const alterarSenha = async (body: { usuario: string; senha: string }) => {
    await handleRequest(apiAlterarSenha(body), {
      loading: 'Carregando...',
      success: 'Usuário e senha alterados com sucesso...',
    })
    await mutate()
    props.onFinish && props.onFinish()
  }

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  return (
    <Container maxW='container.md'>
      <Heading size='lg' my={3}>
        Escolha um usuário e senha padrão
      </Heading>
      <Stack>
        <Text>
          O acesso ao Portal Contábil é feito em duas etapas, primeiro você
          acessa com seu email e verá uma lista de seus clientes Success.
        </Text>
        <Text>
          Para acessar um cliente você precisará de um usuário e senha, esse
          usuário é cadastrado no Sistema Success do cliente. Para poder acessar
          os documentos do cliente será necessário entrar em contato com esse
          cliente e solicitar que ele faça o cadastro/liberação de usuário para
          você.
        </Text>
        <Text>
          Para evitar que você precise digitar um usuário e senha cada vez que
          for acessar um cliente, nessa tela você irá definir um usuário e senha
          padrão. Toda vez que você acessar um cliente o sistema irá tentar
          entrar automaticamente com o usuário e senha que você definiu aqui.
        </Text>
        <Text fontWeight='medium'>
          Atenção! Oriente seus clientes a cadastrarem no sistema Success o
          mesmo usuário e senha usados aqui, caso não sejam o mesmo você terá
          que digitar credenciais diferentes toda vez que for acessar um
          cliente.
        </Text>
        <Text>
          Isso foi feito tendo em mente facilitar o trabalho de contadores com
          vários clientes. Os dados que você irá acessar são de propriedade do
          cliente, e é o cliente quem controla o acesso a essas informações. A
          Success atua apenas como intermediadora, de acordo com a lei de
          proteção de dados LGPD é o cliente que define quem tem permissão de
          acessar seus dados.
        </Text>
      </Stack>
      <Alert my={3} status='info'>
        <AlertIcon />
        Você pode alterar esse usuário ou senha no futuro caso deseje.
      </Alert>
      <Divider mt={8} mb={2} />
      <Box w='full' maxW='md' mx='auto'>
        <form onSubmit={handleSubmit(alterarSenha)}>
          <FormControl mb={3} isInvalid={errors.usuario}>
            <Input
              id='usuario'
              placeholder='Usuário'
              {...register('usuario', {
                required: 'Digite um usuário',
              })}
            />
            <FormErrorMessage>
              {errors.usuario && errors.usuario.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.senha}>
            <Input
              type='password'
              id='senha'
              placeholder='Senha'
              {...register('senha', {
                required: 'Digite uma senha',
              })}
            />
            <FormErrorMessage>
              {errors.senha && errors.senha.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            colorScheme='blue'
            isFullWidth
            my={4}
            isLoading={isSubmitting}
            type='submit'
          >
            Confirmar
          </Button>
        </form>
      </Box>
    </Container>
  )
}
