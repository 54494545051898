import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ContadorLoginDto } from '../../generated'
import { SuccessLogo } from '../Logo/SuccessLogo'
import { EnviarCodigoLoginForm } from './EnviarCodigoLoginForm'

export const ContadorLogin = (props: {
  onEnviar: (body: ContadorLoginDto) => Promise<any>
}) => {
  const [email, setEmail] = useState<string>()

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  const login = (value: { codigoLogin: string }) => {
    if (!email) return
    return props.onEnviar({ email, codigoLogin: value.codigoLogin })
  }

  return (
    <Box d='flex' pt={4} flexDir='column' h='100vh'>
      <SuccessLogo />
      <Box p={2} w='full' maxW='lg' mx='auto' mt={4}>
        <Alert status='info'>
          <AlertIcon />
          <AlertTitle>Portal Contábil</AlertTitle>
        </Alert>
        {email ? (
          <Box>
            <Heading my={4} size='md'>
              Um código de acesso único foi enviado ao seu email, por favor
              digite ou cole o código no campo abaixo.
            </Heading>
            <form onSubmit={handleSubmit(login)}>
              <FormControl isInvalid={errors.codigoLogin}>
                <Input
                  autoComplete='off'
                  id='codigoLogin'
                  placeholder='Código'
                  {...register('codigoLogin', {
                    required: 'Digite o código enviado para o seu email',
                  })}
                />
                <FormErrorMessage>
                  {errors.codigoLogin && errors.codigoLogin.message}
                </FormErrorMessage>
              </FormControl>

              <Button
                colorScheme='blue'
                isFullWidth
                my={4}
                isLoading={isSubmitting}
                type='submit'
              >
                Entrar
              </Button>
            </form>
          </Box>
        ) : (
          <EnviarCodigoLoginForm onSucesso={setEmail} />
        )}

        {/* <Button
              isLoading={stateLogin.loading}
              colorScheme='blue'
              type='submit'
              isFullWidth
              my={2}
            >
              Entrar
            </Button> */}

        <Center mt={2}>
          <OrientacoesModal />
        </Center>
      </Box>
    </Box>
  )
}

const OrientacoesModal = () => {
  const state = useDisclosure()

  return (
    <>
      <Button onClick={state.onOpen} variant='ghost'>
        Clique aqui para orientações
      </Button>
      <Modal size='2xl' isOpen={state.isOpen} onClose={state.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Orientações de acesso</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align='flex-start'>
              <Text>
                Caro contador, o acesso ao portal é feito através do seu email.
                O email utilizado aqui deve ser o mesmo que consta no seu
                registro no cadastro de contadores da Success.
              </Text>
              <Text>
                É importante então que seus dados estejam atualizados junto a
                Success, caso necessário entre em contato com a Success, fale
                com nosso setor de atendimento e diga que deseja atualizar seu
                email no cadastro de contadores.
              </Text>
              <Text fontWeight='medium'>(38) 3671-5156</Text>
              <Text fontWeight='medium'>(38)98825-2024</Text>
              <Text>
                Toda vez que você acessar o portal contábil a partir de um novo
                dispositivo será enviado um código de acesso único para o seu
                email. Você precisará digitar esse código aqui para liberar o
                acesso. Você é responsável por gerenciar seus dispositivos, caso
                acesse o portal a partir de um dispositivo que é usado por mais
                pessoas você pode usar a opção "Desconectar" ao sair para
                garantir a segurança dos dados de seus clientes.
              </Text>
              <Text>
                O papel da Success no portal contábil é atuar como uma ponte
                facilitando a comunicação entre o contador e o cliente. Manter o
                seu dispositivo seguro é responsabilidade sua.
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' onClick={state.onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
