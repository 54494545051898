import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { CgMenu } from 'react-icons/cg'
import { FaHome, FaRegFileAlt } from 'react-icons/fa'
import { atom, useRecoilState } from 'recoil'
import { ExtraElements } from './HeaderElements'

import { SidebarContent } from './SidebarContent'

export const sidebarState = atom({
  key: 'sidebarState',
  default: false,
})

export type Rota = {
  path: string
  name: string
  icon?: ReactNode
  routes?: Rota[]
}
const FontSpan = ({ children }: { children: ReactNode }) => (
  <span className='font-mono font-black text-md -ml-1'>{children} </span>
)

export const routes: Rota[] = [
  {
    name: 'Painel',
    path: '/',
    icon: <FaHome />,
  },
  {
    name: 'Documentos',
    path: '/todos',
    icon: <FaRegFileAlt />,
  },
  {
    name: ' ',
    path: '/nfe',
    icon: <FontSpan>NFe</FontSpan>,
    routes: [
      {
        name: 'NFe Emitidas',
        path: '/nfe/emitidas',
        icon: <FaRegFileAlt />,
      },
      {
        name: 'NFe Terceiros',
        path: '/nfe/terceiros',
        icon: <FaRegFileAlt />,
      },
    ],
  },
  {
    name: ' ',
    path: '/nfce',
    icon: <FontSpan>NFCe</FontSpan>,
  },

  {
    name: ' ',
    path: '/cte',
    icon: <FontSpan>CTe</FontSpan>,
    routes: [
      {
        name: 'CTe Emitidas',
        path: '/cte/emitidas',
        icon: <FaRegFileAlt />,
      },

      {
        name: 'CTe Terceiros',
        path: '/cte/terceiros',
        icon: <FaRegFileAlt />,
      },
    ],
  },

  {
    name: ' ',
    path: '/mdfe',
    icon: <FontSpan>MDFe</FontSpan>,
  },
]

export const AppShell = ({ children }: { children: ReactNode }) => {
  const sidebar = useDisclosure()

  const [collapsed, setCollapsed] = useRecoilState(sidebarState)

  const margin = useMemo(() => (collapsed ? 14 : 52), [collapsed])

  const onOpen = () => {
    setCollapsed(false)
    sidebar.onOpen()
  }

  return (
    <Box
      as='section'
      bg={useColorModeValue('gray.50', 'gray.700')}
      minH='100vh'
    >
      <SidebarContent display={{ base: 'none', md: 'flex' }} />
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement='left'
        allowPinchZoom
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w='full' borderRight='none' />
        </DrawerContent>
      </Drawer>
      <Box ml={{ sm: 0, md: margin }} transition='.3s ease'>
        <Flex
          as='header'
          align='center'
          justify='space-between'
          w='full'
          px='4'
          bg='blue.100'
          borderBottomWidth='1px'
          borderColor={useColorModeValue('inherit', 'gray.700')}
          h='14'
        >
          <IconButton
            aria-label='Menu'
            display={{ base: 'inline-flex', md: 'none' }}
            rounded={4}
            onClick={onOpen}
            icon={<CgMenu />}
            size='sm'
          />

          <Flex w='full' justify='flex-end' align='center'>
            <ExtraElements />
          </Flex>
        </Flex>

        <Box>{children}</Box>
      </Box>
    </Box>
  )
}
