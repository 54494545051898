import moment from 'moment'
import { atom, selector } from 'recoil'
import { dataToString } from '../lib/utils'
import { paginacaoState, sortState } from './paginacaoState'

export const filtroEmpresaState = atom<string[]>({
  default: [],
  key: 'filtroEmpresa',
})

export const filtroStatusState = atom<string[]>({
  default: [],
  key: 'filtroStatus',
})

export type TipoModelo =
  | 'nfe-emitida'
  | 'nfe-terceiro'
  | 'nfce'
  | 'cte-emitida'
  | 'cte-terceiro'
  | 'mdfe'

export const filtroModeloState = atom<TipoModelo[]>({
  default: [],
  key: 'filtroModelo',
})

export const filtroSerieState = atom<string>({
  key: 'filtroSerie',
  default: '',
})

export const filtroCodigoState = atom<string>({
  key: 'filtroCodigo',
  default: '',
})

export const filtroChaveState = atom<string>({
  key: 'filtroChave',
  default: '',
})

export const filtroIgnorarBaixadosState = atom<boolean>({
  default: false,
  key: 'ignorarBaixados',
})

export const filtroTipoState = atom<string>({
  default: '',
  key: 'filtroTipo',
})

export const getPeriodo = () => {
  const diaDoMes = moment().date();
  let mesAtual;
  if (diaDoMes <= 5) {
    mesAtual = moment().subtract(1, 'month');
  } else {
    mesAtual = moment();
  }
  const primeiroDia = mesAtual.startOf('month').toDate();
  const ultimoDia = mesAtual.endOf('month').toDate();
  return { primeiroDia, ultimoDia };
};

export const filtroPeriodoState = atom({
  key: 'filtroPeriodo',
  default: {
    inicio: getPeriodo().primeiroDia,
    fim: getPeriodo().ultimoDia,
  },
})

export const periodoStringState = selector({
  key: 'periodoString',
  get: ({ get }) => {
    const inicio = dataToString(get(filtroPeriodoState).inicio)
    const fim = dataToString(get(filtroPeriodoState).fim)
    return { inicio, fim }
  },
})

export const allFiltrosState = selector({
  key: 'allFiltros',
  get: ({ get }) => {
    const periodo = get(periodoStringState)
    const status = get(filtroStatusState)
    const serie = get(filtroSerieState)
    const codigo = get(filtroCodigoState)
    const chave = get(filtroChaveState)
    const sort = get(sortState)
    const ignorarBaixados = get(filtroIgnorarBaixadosState)
    const modelo = get(filtroModeloState)
    const paginacao = get(paginacaoState)
    const coluna = sort?.coluna ?? undefined
    const sortOrder: string = sort?.desc ? 'desc' : 'asc'

    return {
      dataInicio: periodo.inicio,
      dataFim: periodo.fim,
      pagina: paginacao.current,
      limit: paginacao.pageSize,
      status,
      serie,
      codigo,
      chave,
      coluna,
      sortOrder,
      modelo,
      ignorarBaixados,
    }
  },
})
