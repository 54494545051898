import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { paginacaoState } from 'store/paginacaoState'
import useSWR from 'swr'
import { apiGetTodosDocumentos } from '../lib/api/apiDocumentos'
import { allFiltrosState } from '../store/filtrosState'
import { useCheckTotal } from './useCheckTotal'

export const useTodosDocumentos = () => {
  const [paginacao, setPaginacao] = useRecoilState(paginacaoState)

  const {
    dataInicio,
    dataFim,
    pagina,
    limit,
    status,
    serie,
    codigo,
    chave,
    coluna,
    sortOrder,
    modelo,
    ignorarBaixados,
  } = useRecoilValue(allFiltrosState)

  useEffect(() => {
    setPaginacao({ ...paginacao, current: 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInicio, dataFim, status, modelo, serie, codigo, chave, coluna])

  const { data, error, isValidating, mutate } = useSWR(
    [
      'todos/get',
      dataInicio,
      dataFim,
      pagina,
      limit,
      status,
      serie,
      codigo,
      chave,
      coluna,
      sortOrder,
      modelo,
      ignorarBaixados,
    ],
    (
      _url,
      dataInicio,
      dataFim,
      pagina,
      limit,
      status,
      serie,
      codigo,
      chave,
      coluna,
      sortOrder,
      modelo,
      ignorarBaixados
    ) =>
      apiGetTodosDocumentos({
        dataInicio,
        dataFim,
        pagina,
        limit,
        status,
        serie,
        codigo,
        chave,
        coluna,
        sortOrder,
        modelo,
        ignorarBaixados,
      })
  )

  const documentos = data && data.data
  const isLoading = !error && !data

  const total = (documentos && documentos.count) || 0

  useCheckTotal({ isLoading, isValidating, total })

  return {
    isLoading,
    data,
    error,
    documentos,
    mutate,
  }
}
