import { apiGetEmpresas } from 'lib/api/api'
import { handleError } from 'lib/errorHandler'
import { useMemo } from 'react'
import useSWR from 'swr'
import { ClienteRegistro } from '../generated'

export const useEmpresas = (cliente: ClienteRegistro) => {
  const contrato = useMemo(() => cliente?.codigo, [cliente])

  const { data, error } = useSWR(
    cliente.configurado ? contrato : null,
    apiGetEmpresas
  )

  const empresas = data && data.data
  const isLoading = !error && !data

  const errorMessage = useMemo(() => (error ? handleError(error) : null), [
    error,
  ])

  return { isLoading, empresas, error, errorMessage }
}
