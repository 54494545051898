import { Dashboard } from 'pages/Dashboard'
import { FrontPage } from 'pages/Documentos/FrontPage'
import { ModeloDocPageComponent } from 'pages/Documentos/ModeloDocPageComponent'
import { TodosPage } from 'pages/Documentos/TodosPage'
import { NotFound } from 'pages/NotFound'
import React from 'react'
import { Route, Routes } from 'react-router'

export const RoutesComponent = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard />}>
        <Route path='/' element={<FrontPage />} />
        <Route path='/todos' element={<TodosPage />} />
        <Route
          path='/nfe/emitidas'
          element={<ModeloDocPageComponent modelo='nfe-emitida' />}
        />
        <Route
          path='/nfe/terceiros'
          element={<ModeloDocPageComponent modelo='nfe-terceiro' />}
        />
        <Route
          path='/nfce'
          element={<ModeloDocPageComponent modelo='nfce' />}
        />
        <Route
          path='/cte/emitidas'
          element={<ModeloDocPageComponent modelo='cte-emitida' />}
        />
        <Route
          path='/cte/terceiros'
          element={<ModeloDocPageComponent modelo='cte-terceiro' />}
        />
        <Route
          path='/mdfe'
          element={<ModeloDocPageComponent modelo='mdfe' />}
        />
      </Route>
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
