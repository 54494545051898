import {
  ClienteRegistro,
  ContadorLoginDto,
  ContadorRegistroCompleto,
  EnviarCodigoLoginDto,
} from '../../generated'
import { AlterarSenhaDto } from '../../generated/models/AlterarSenhaDto'
import { api } from './api'

export const apiContadorLogin = async (body: ContadorLoginDto) =>
  api.post<string>('contador/login', body)

export const apiGetContador = async () => {
  try {
    const res = await api.get<ContadorRegistroCompleto>('contador/get')
    return res
  } catch (err: any) {
    if (err.response) return { data: null, error: err }
    throw err
  }
}

export const apiEnviarCodigoLogin = async (body: EnviarCodigoLoginDto) =>
  await api.post<string>('contador/codigo-login', body)

export const apiAlterarSenha = async (body: AlterarSenhaDto) =>
  api.post<string>('contador/alterar-senha', body)

export const apiGetClientes = async () =>
  api.get<ClienteRegistro[]>('contador/clientes')
