import { Divider, Drawer, Result, Spin, Steps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  AlertNumeroDocs,
  DownloadFunction,
  MetodoDownloadContainer,
} from './DownloadModal'
import { PendenciasModoContainer } from './VerificarPendenciasButton'
import { Flex } from '@chakra-ui/react'



export const BaixarDocsDrawer = (props: {
  modo: 'selecao' | 'query'
  numeroDocs: number
  isOpen: boolean
  onClose: () => void
  onBaixar: any
  selecao: string[] | any
  modelo: string[]
  onBaixarPDF: any
  emailSelecao: any
  baixarXmlsEPDFsZipados: any
  isTodosOpen: boolean
  baixarTodosXmlsEConverterParaPdf: any
  baixarTodosXmlsEPdfs: any
}) => {
  const [passo, setPasso] = useState(0)

  useEffect(() => {
    setPasso(0)
  }, [props.isOpen])

  // const fazerDownload = async ({
  //   selecao,
  //   modelo
  // }: {
  //   selecao: string[];
  //   modelo: string;
  // }) => {
  //   setPasso(2)
  //   await props.onBaixar()
  //   props.onClose()
  //   setPasso(0)
  // }

  const fazerDownload: DownloadFunction = async ({ porEmail, email }) => {
    setPasso(2)
    await props.onBaixar({ porEmail, email })
    props.onClose()
    setPasso(0)
  }

  const fazerDownloadPDF = async ({
    selecao,
    modelo
  }: {
    selecao: string[];
    modelo: string;
  }) => {
    setPasso(2)
    await props.onBaixarPDF()
    props.onClose()
    setPasso(0)
  }

  const fazerDownloadEmail: DownloadFunction = async ({ porEmail, email }) => {
    setPasso(2)
    await props.onBaixar({ porEmail, email })
    props.onClose()
    setPasso(0)
  }

  const fazerDownloadAmbos = async ({
    selecao,
    modelo
  }: {
    selecao: string[];
    modelo: string;
  }) => {
    setPasso(2)
    await props.baixarXmlsEPDFsZipados()
    props.onClose()
    setPasso(0)
  }

  const fazerDownloadTodosPDF: DownloadFunction = async ({ porEmail, email }) => {
    setPasso(2)
    await props.baixarTodosXmlsEConverterParaPdf({ porEmail, email })
    props.onClose()
    setPasso(0)
  }

  const fazerDownloadTodosXMLePDF: DownloadFunction = async ({ porEmail, email }) => {
    setPasso(2)
    await props.baixarTodosXmlsEPdfs({ porEmail, email })
    props.onClose()
    setPasso(0)
  }

  return (
    <Drawer
      onClose={props.onClose}
      visible={props.isOpen}
      placement='bottom'
      height='100%'
      title={
        <div className='flex justify-between items-center'>
          <Typography>Baixar Documentos</Typography>
          <AlertNumeroDocs numeroDocs={props.numeroDocs} />
        </div>
      }
    >
      <Steps current={passo}>
        <Steps.Step key='pendencias' title='Verificar pendências' />
        <Steps.Step key='receber' title='Metódo de recebimento' />
        <Steps.Step key='download' title='Download' />
      </Steps>
      <Divider />
      <div>
        {passo === 0 && (
          <PendenciasModoContainer
            modo={props.modo}
            mostrarConcordar
            onConcordar={() => setPasso(1)}
          />
        )}
        {passo === 1 && (
          <MetodoDownloadContainer 
          onProsseguir={fazerDownload}
          onProsseguirPDF={fazerDownloadPDF}
          onProsseguirAmbos={fazerDownloadAmbos}
          onProsseguirEmail={fazerDownloadEmail}
          onClose={props.isTodosOpen}
          onProsseguirTodosPDF={fazerDownloadTodosPDF}
          onProsseguirTodosPDFeXML={fazerDownloadTodosXMLePDF}
          modelo={props.modelo}
         />
        )}
        {passo === 2 && (
          <Flex justifyContent={'center'} flexDirection={'column'}>
            <Result
            status='info'
            title='Seus documentos estão sendo preparados.'
          />
          <>
          <Spin tip="Isso pode demorar alguns minutos" size="large" />
          </>
          </Flex>
        )}
      </div>
    </Drawer>
  )
}
