import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import useSWR from 'swr'
import {
  apiVerificarPendencias,
  apiVerificarPendenciasByChaves,
} from '../lib/api/apiDocumentos'
import { selecaoChaveState } from '../store/selecaoState'
import { useFiltros } from './useFiltros'

export const usePendencias = () => {
  const {
    dataInicio,
    dataFim,
    modelo,
    status,
    chave,
    codigo,
    serie,
  } = useFiltros()

  const { data, error } = useSWR(
    [
      'documento/pendencias',
      dataInicio,
      dataFim,
      modelo,
      status,
      chave,
      codigo,
      serie,
    ],
    (_url, dataInicio, dataFim, modelo, status, chave, codigo, serie) =>
      apiVerificarPendencias({
        dataInicio,
        dataFim,
        chave,
        modelo,
        codigo,
        serie,
        status,
      })
  )

  const pendencias = useMemo(() => data && data.data, [data])
  // const isLoading = isValidating || (!error && !data)
  const isLoading = !error && !data

  const numeroPendencias = useMemo(() => {
    if (!pendencias) return 0
    const saltos = (pendencias.saltos && pendencias.saltos.length) ?? 0
    const statusInvalidos = pendencias.docsStatusInvalidos.length ?? 0
    const xmlAusentes = pendencias.docsXmlAusente.length ?? 0
    return saltos + statusInvalidos + xmlAusentes
  }, [pendencias])

  return { isLoading, pendencias, error, numeroPendencias }
}

export const usePendenciasByChaves = () => {
  const selecao = useRecoilValue(selecaoChaveState)

  const { data, error } = useSWR(
    ['documento/pendenciasByChaves', selecao],
    (_url, selecao) =>
      apiVerificarPendenciasByChaves({ chaves: selecao as string[] })
  )

  const pendencias = useMemo(() => data && data.data, [data])
  // const isLoading = isValidating || (!error && !data)
  const isLoading = !error && !data

  const numeroPendencias = useMemo(() => {
    if (!pendencias) return 0
    const saltos = (pendencias.saltos && pendencias.saltos.length) ?? 0
    const statusInvalidos = pendencias.docsStatusInvalidos.length ?? 0
    const xmlAusentes = pendencias.docsXmlAusente.length ?? 0
    return saltos + statusInvalidos + xmlAusentes
  }, [pendencias])

  return { isLoading, pendencias, error, numeroPendencias }
}
