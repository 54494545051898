import {
  Box,
  BoxProps,
  Collapse,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { useMatch, useNavigate } from 'react-router-dom'
import { useBoolean } from 'react-use'
import { useRecoilValue } from 'recoil'
import { Rota, sidebarState } from './AppShell'

export const NavItem = (props: Rota & BoxProps) => {
  const collapsed = useRecoilValue(sidebarState)
  const { icon, name, path, routes, ...rest } = props

  const [aberto, toggle] = useBoolean(false)
  const navigate = useNavigate()

  const match = useMatch(path)

  const onClick = () => (routes ? toggle() : navigate(path))

  return (
    <>
      <Flex
        align='center'
        px='4'
        pl='4'
        py='3'
        cursor='pointer'
        color={useColorModeValue('inherit', 'gray.400')}
        bg={match ? 'gray.100' : 'inherit'}
        _hover={{
          bg: 'gray.100',
          color: 'gray.900',
        }}
        role='group'
        fontWeight='semibold'
        transition='.15s ease'
        onClick={onClick}
        {...rest}
      >
        <Box mr={2} mx={collapsed ? 'auto' : undefined} transitionDelay='.7s'>
          {icon}
        </Box>
        {!collapsed && <Text>{name}</Text>}
        {routes && (
          <Icon
            as={MdKeyboardArrowRight}
            ml='auto'
            transform={aberto ? 'rotate(90deg)' : ''}
          />
        )}
      </Flex>
      {routes && (
        <Collapse in={aberto}>
          {routes.map(r => (
            <NavItem key={r.path} {...r} />
          ))}
        </Collapse>
      )}
    </>
  )
}
