import { Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React from 'react'
import { MdFileDownload } from 'react-icons/md'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useDownload } from '../hooks/useDownload'
import { totalState } from '../store/paginacaoState'
import { selecaoChaveState, selecaoState } from '../store/selecaoState'
import {
  downloadSelecionadosModalState,
  downloadTodosModalState,
} from '../store/uiState'
import { BaixarDocsDrawer } from './DownloadAllModal'
import { ResponsiveButton } from './ResponsiveButton'
import { filtroModeloState } from 'store/filtrosState'
import toast from 'react-hot-toast'
import { handlePromise } from 'lib/errorHandler'

export const DownloadButtonContainer = () => {
  const { baixarSelecao, baixarTodasPaginas, baixarVariosXmls, baixarVariosPDFs, baixarXmlsEPDFsZipados, baixarTodosXmlsEConverterParaPdf, baixarTodosXmlsEPdfs } = useDownload()

  const [isSelecionadosOpen, toggleSelecionados] = useRecoilState(
    downloadSelecionadosModalState
  )
  const [isTodosOpen, toggleTodos] = useRecoilState(downloadTodosModalState)

  const selecao = useRecoilValue(selecaoState)
  const selecaoChave = useRecoilValue(selecaoChaveState)

  const temSelecao = selecao.length > 0
  const total = useRecoilValue(totalState)

  const modelo = useRecoilValue(filtroModeloState)
  const emissao = new Date()
  const modeloString = modelo[0]

  const handleVariousXmls = async () => {
    if (!selecaoChave) {
      toast.error('Chaves não selecionadas');
      return;
    }
  
    try {
      await handlePromise(
        baixarVariosXmls(selecaoChave, modeloString, emissao),
        { success: 'Download concluído com sucesso', loading: 'Preparando download...' }
      );
    } catch (error) {
      toast.error(`Erro ao baixar XML: ${error}`);
    }
  };
 
  const handleVariousPDFs = async () => {
    if (!selecaoChave) {
      toast.error('Chaves não selecionadas');
      return;
    }
  
    try {
      await handlePromise(
        baixarVariosPDFs(selecaoChave, modeloString, emissao),
        { success: 'Download concluído com sucesso', loading: 'Preparando download...' }
      );
    } catch (error) {
      toast.error(`Erro ao baixar PDF: ${error}`);
    }
  };


  const handleVariousXMLSandPDFs = async () => {
    if (!selecaoChave) {
      toast.error('Chaves não selecionadas');
      return;
    }
  
    try {
      await handlePromise(
        baixarXmlsEPDFsZipados(selecaoChave, modeloString, emissao),
        { success: 'Download concluído com sucesso', loading: 'Preparando download...' }
      );
    } catch (error) {
      toast.error(`Erro ao baixar PDF: ${error}`);
    }
  };

  return (
    <div>
      <Menu>
        <MenuButton
          colorScheme='blue'
          as={ResponsiveButton}
          size='sm'
          icon={MdFileDownload}
          // rightIcon={<MdKeyboardArrowDown />}
        >
          Download
        </MenuButton>
        <MenuList>
          <MenuItem
            isDisabled={!temSelecao}
            onClick={() => toggleSelecionados(true)}
            icon={<Icon fontSize='lg' as={MdFileDownload} />}
          >
            Baixar Selecionados ({selecao.length})
          </MenuItem>
          <MenuItem
            isDisabled={!total}
            onClick={() => toggleTodos(true)}
            icon={<Icon fontSize='lg' as={MdFileDownload} />}
          >
            Baixar Tudo ({total})
          </MenuItem>
        </MenuList>
      </Menu>
      <BaixarDocsDrawer
        modo='selecao'
        numeroDocs={selecao.length}
        onClose={() => toggleSelecionados(false)}
        isOpen={isSelecionadosOpen}
        onBaixar={handleVariousXmls}
        selecao={selecaoChave}
        modelo={modelo}
        onBaixarPDF={handleVariousPDFs}
        emailSelecao={baixarSelecao}
        baixarXmlsEPDFsZipados={handleVariousXMLSandPDFs}
        isTodosOpen={isTodosOpen}
        baixarTodosXmlsEConverterParaPdf={baixarTodosXmlsEConverterParaPdf}
        baixarTodosXmlsEPdfs={baixarTodosXmlsEPdfs}
        
      />
      <BaixarDocsDrawer
        modo='query'
        numeroDocs={total}
        onClose={() => toggleTodos(false)}
        isOpen={isTodosOpen}
        onBaixar={baixarTodasPaginas}
        selecao={selecaoChave}
        modelo={modelo}
        onBaixarPDF={handleVariousPDFs}
        emailSelecao={baixarSelecao}
        baixarXmlsEPDFsZipados={handleVariousXMLSandPDFs}
        isTodosOpen={isTodosOpen}
        baixarTodosXmlsEConverterParaPdf={baixarTodosXmlsEConverterParaPdf}
        baixarTodosXmlsEPdfs={baixarTodosXmlsEPdfs}
      />
    </div>
  )
}
