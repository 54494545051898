import { Center, IconButton, Tooltip } from '@chakra-ui/react';
import { memo, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { MdCloudDownload, MdFileDownload, MdReceipt } from 'react-icons/md';
import { useAsyncFn } from 'react-use';
import { stringToData } from '../lib/utils';
import { Popconfirm } from 'antd';
import PdfViewerComponent from './Basico/PdfViewerComponent';

export const DownloadDocButton = memo(
  (props: {
    chave?: string;
    emissao: string;
    modelo: string;
    baixarUmXml: (chave: string, modelo: string, emissao: Date) => Promise<void>;
    baixarPDF: (chave: string, modelo: string, emissao: Date) => Promise<void>;
    baixarUmXmlEConverterParaPdf: (chave: string, modelo: string, emissao: Date) => Promise<any>;
  }) => {
    const { chave, emissao, modelo, baixarUmXml, baixarUmXmlEConverterParaPdf, baixarPDF } = props;

    const [visibleXml, setVisibleXml] = useState(false);
    const [stateXml, fetchXml] = useAsyncFn(baixarUmXml);

    const dataEmissao = useMemo(() => stringToData(emissao), [emissao]);

    const [visiblePdfViewer, setVisiblePdfViewer] = useState(false);
    const [pdfDataUri, setPdfDataUri] = useState('');

    const handleOkXml = async () => {
      if (!chave) {
        toast.error('Documento com chave inválido!');
        return;
      }

      await fetchXml(chave, modelo, dataEmissao);
      setVisibleXml(false);
    };

    const handleDownloadPdf = async () => {
      if (!chave) {
        toast.error('Documento com chave inválido!');
        return;
      }

      try {
        const pdfDataUriResponse = await baixarUmXmlEConverterParaPdf(chave, modelo, dataEmissao);
        setPdfDataUri(pdfDataUriResponse);
        setVisiblePdfViewer(true);
      } catch (error) {
        toast.error(`Erro ao baixar PDF: ${error}`);
      }
    };

    const handleBaixarPDF = async () => {
      if (!chave) {
        toast.error('Documento com chave inválido!');
        return;
      }

      try {
        await baixarPDF(chave, modelo, dataEmissao);
      } catch (error) {
        toast.error(`Erro ao baixar PDF: ${error}`);
      }
    };

    const closePdfViewer = () => {
      setVisiblePdfViewer(false);
    };

    return (
      <Center css={'gap: 5px'}>
        <div>
          <Popconfirm
            placement='leftBottom'
            title='Fazer o download do XML?'
            okText='Sim'
            onVisibleChange={(v, e) => {
              if (!e) setVisibleXml(v);
            }}
            visible={visibleXml}
            onConfirm={handleOkXml}
            okButtonProps={{ loading: stateXml.loading }}
            cancelText='Cancelar'
            onCancel={() => setVisibleXml(false)}
          >
            <Tooltip label='Fazer download do XML'>
              <IconButton
                aria-label='download-xml'
                size='xs'
                colorScheme='blue'
                icon={<MdCloudDownload />}
                rounded={4}
                onClick={() => setVisibleXml(true)}
              />
            </Tooltip>
          </Popconfirm>
        </div>
        {(modelo === 'nfe-emitida' || modelo === 'nfe-terceiro') ? (
          <>
            <div>
              <Tooltip label='Visualizar DANFE'>
                <IconButton
                  aria-label='visualizar-pdf'
                  size='xs'
                  colorScheme='blue'
                  icon={<MdReceipt />}
                  rounded={4}
                  onClick={handleDownloadPdf}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip label='Fazer download DANFE'>
                <IconButton
                  aria-label='baixar-pdf'
                  size='xs'
                  colorScheme='blue'
                  icon={<MdFileDownload />}
                  rounded={4}
                  onClick={handleBaixarPDF}
                />
              </Tooltip>
            </div>
          </>
        ) : null}
        {visiblePdfViewer && <PdfViewerComponent pdfContent={pdfDataUri} visiblePdfViewer={visiblePdfViewer} onClose={closePdfViewer} />}
      </Center>
    );
  }
);