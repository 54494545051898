import useSWR from 'swr'
import { ContadorLoginDto } from '../generated'
import { apiContadorLogin, apiGetContador } from '../lib/api/apiContador'
import { handleRequest } from '../lib/errorHandler'

export const useContador = () => {
  const { data, error, mutate, isValidating } = useSWR(
    'contador',
    apiGetContador,
    {
      shouldRetryOnError: false,
    }
  )

  const contador = data?.data
  const loading = !data && !error

  const login = async (body: ContadorLoginDto) => {
    const res = await handleRequest(apiContadorLogin(body), {
      success: 'Bem vindo!',
      loading: 'Fazendo login...',
    })
    if (res.data) {
      localStorage.setItem('contador-token', res.data)
      await mutate()
    }
  }

  const logout = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('contador-token')
    await mutate()
  }

  return { contador, error, loading, login, logout, isValidating, mutate }
}
