import { format } from 'date-fns'

import fileDownload from 'js-file-download'
import toast from 'react-hot-toast'
import { useRecoilValue } from 'recoil'
import { DownloadFunction, DownloadFunctionOptions } from '../components/DownloadModal'
import { GetDocumentosDto } from '../generated'
import {
  apiQueryXmlDownload,
  apiQueryXmlDownloadComStatus,
  apiQueryXmlEmail,
  apiSelecaoXmlDownload,
  apiSelecaoXmlEmail,
  apiUmXml,
} from '../lib/api/api'
import { handleRequest } from '../lib/errorHandler'
import { empresaSelecionadaState } from '../store/empresaSelecionadaState'
import {
  filtroCodigoState,
  filtroModeloState,
  filtroSerieState,
  filtroStatusState,
  periodoStringState,
} from '../store/filtrosState'
import { selecaoChaveState } from '../store/selecaoState'
import { useTodosDocumentos } from './useTodosDocumentos'
import JSZip from 'jszip'
import { saveAs } from 'file-saver';

export const useDownload = () => {
  const { mutate } = useTodosDocumentos()

  const { inicio: dataInicio, fim: dataFim } = useRecoilValue(
    periodoStringState
  )

  const status = useRecoilValue(filtroStatusState)
  const serie = useRecoilValue(filtroSerieState)
  const codigo = useRecoilValue(filtroCodigoState)

  const selecaoChave = useRecoilValue(selecaoChaveState)
  const modelo = useRecoilValue(filtroModeloState)
  const empresa = useRecoilValue(empresaSelecionadaState)

  const baixarTodasPaginas: DownloadFunction = async ({ porEmail, email }) => {
    const body: GetDocumentosDto = {
      dataInicio,
      dataFim,
      status,
      serie,
      codigo,
      modelo,
    }
  
    if (porEmail) {
      if (!email) {
        toast.error('Email inválido!')
        return
      }
      const pedido = await handleRequest(apiQueryXmlEmail({ body, email }), {
        success: 'Email enviado!',
        loading: 'Preparando email...',
      }).then(() => mutate())
  
      console.log(pedido)
    } else {
      const arquivo = await handleRequest(
        apiQueryXmlDownload({ filtros: body }),
        {
          success: 'Download concluído',
          loading: 'Preparando download...',
        }
      ).then(v => {
        mutate()
        return v
      })
  
      const fileName = `CNPJ-${empresa?.cnpj} ${format(
        new Date(),
        `dd/MM/yyyy HH'h' mm'm'`
      )}.zip`
  
      fileDownload(arquivo.data, fileName)
    }
  }
  

  const baixarTodosXmlsEConverterParaPdf = async ({ porEmail, email }: DownloadFunctionOptions) => {
    try {
      const body: GetDocumentosDto = {
        dataInicio,
        dataFim,
        status,
        serie,
        codigo,
        modelo,
      };
  
      const zip = new JSZip();
  
      if (porEmail) {
        if (!email) {
          toast.error('Email inválido!');
          return;
        }
  
        const pedido = await handleRequest(apiQueryXmlEmail({ body, email }), {
          success: 'Email enviado!',
          loading: 'Preparando email...',
        }).then(() => mutate());
        console.log(pedido);
      } else {
        const response = await handleRequest(apiQueryXmlDownloadComStatus({ filtros: body }), {
          success: 'Download concluído',
          loading: 'Preparando download...',
        }).then((v) => {
          mutate();
          return v;
        });
  
        const arquivos = response.data;
  
        for (let i = 0; i < arquivos.length; i++) {
          const arquivo = arquivos[i];
  
          if (arquivo.status === 'C') {
            // console.log(`Arquivo com status "C" (Cancelado) encontrado. Ignorando a conversão para PDF.`);
            continue; // Pule para a próxima iteração
          }
  
          try {
            // Nova requisição para a API de conversão XML para PDF
            const pdfConversionResponse = await fetch('https://ws.meudanfe.com/api/v1/get/nfe/xmltodanfepdf/API', {
              method: 'POST',
              headers: {
                'Content-Type': 'text/plain',
              },
              body: arquivo.xml,
            });
  
            if (pdfConversionResponse.ok) {
              // Obter o conteúdo do PDF gerado
              let pdfContentBase64 = await pdfConversionResponse.text();
  
              // Remova as aspas do início e do fim, se presentes
              pdfContentBase64 = pdfContentBase64.replace(/^"|"$/g, '');
  
              // Converter a base64 para um array de bytes
              const pdfData = Uint8Array.from(atob(pdfContentBase64), (c) => c.charCodeAt(0));
  
              // Adicionar o PDF ao zip
              zip.file(`PDF-CNPJ-${empresa?.cnpj} ${format(new Date(), 'dd-MM-yyyy HH\'h\' mm\'m\'')} ${i + 1}.pdf`, pdfData);
            } else {
              console.error(`Falha na requisição para a API de conversão. Código de status: ${pdfConversionResponse.status}`,)
            }
          } catch (error) {
            console.error('Erro ao converter XML para PDF:', error);
          }
        }
  
        const zipBuffer = await zip.generateAsync({ type: 'blob' });
        const zipFileName = `CNPJ-${empresa?.cnpj} ${format(new Date(), 'dd-MM-yyyy HH\'h\' mm\'m\'')} ArquivosPDF.zip`;
  
        // Salva o arquivo ZIP
        if (typeof navigator ) {
          const downloadElem = document.createElement('a');
          const url = URL.createObjectURL(zipBuffer);
          document.body.appendChild(downloadElem);
          downloadElem.href = url;
          downloadElem.download = zipFileName;
          downloadElem.click();
          downloadElem.remove();
          window.URL.revokeObjectURL(url);
        } else {
          saveAs(zipBuffer, zipFileName);
        }
      }
    } catch (error) {
      console.error('Erro ao baixar XMLs e converter para PDF:', error);
      throw error;
    }
  };
  
  
  const baixarTodosXmlsEPdfs = async ({ porEmail, email }: DownloadFunctionOptions) => {
    try {
        const body: GetDocumentosDto = {
            dataInicio,
            dataFim,
            status,
            serie,
            codigo,
            modelo,
        };

        const zip = new JSZip();

        if (porEmail) {
            if (!email) {
                toast.error('Email inválido!');
                return;
            }

            const pedido = await handleRequest(apiQueryXmlEmail({ body, email }), {
                success: 'Email enviado!',
                loading: 'Preparando email...',
            }).then(() => mutate());

            console.log(pedido);
        } else {
            // Baixar todos os XMLs
            const arquivoXML = await handleRequest(apiQueryXmlDownloadComStatus({ filtros: body }), {
                success: 'Download concluído',
                loading: 'Preparando download aguarde...',
            }).then(v => {
                mutate();
                return v;
            });

            const arquivosXML = arquivoXML.data;

            for (let i = 0; i < arquivosXML.length; i++) {
                const arquivo = arquivosXML[i];

                zip.file(`XML-CNPJ-${empresa?.cnpj} ${format(new Date(), 'dd-MM-yyyy HH\'h\' mm\'m\'')} ${i + 1}.xml`, arquivo.xml);

                if (arquivo.status === 'C') {
                    // console.log(`Arquivo XML com status "C" encontrado. Ignorando a conversão para PDF.`);
                    continue; // Pule para a próxima iteração
                }

                try {
                    const pdfConversionResponse = await fetch('https://ws.meudanfe.com/api/v1/get/nfe/xmltodanfepdf/API', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'text/plain',
                        },
                        body: arquivo.xml,
                    });

                    if (pdfConversionResponse.ok) {
                        // Obter o conteúdo do PDF gerado
                        let pdfContentBase64 = await pdfConversionResponse.text();

                        // Remova as aspas do início e do fim
                        pdfContentBase64 = pdfContentBase64.replace(/^"|"$/g, '');

                        // Converter a base64 para um array de bytes
                        const pdfData = Uint8Array.from(atob(pdfContentBase64), (c) => c.charCodeAt(0));

                        // Adicionar o PDF ao zip
                        zip.file(`PDF-CNPJ-${empresa?.cnpj} ${format(new Date(), 'dd-MM-yyyy HH\'h\' mm\'m\'')} ${i + 1}.pdf`, pdfData);
                    } else {
                        console.error(`Falha na requisição para a API de conversão. Código de status: ${pdfConversionResponse.status}`);
                    }
                } catch (error) {
                    console.error('Erro ao converter XML para PDF:', error);
                }
            }

            const zipBuffer = await zip.generateAsync({ type: 'blob' });
            const zipFileName = `CNPJ-${empresa?.cnpj} ${format(new Date(), 'dd-MM-yyyy HH\'h\' mm\'m\'')} ArquivosComXMLsEPDFs.zip`;

            const downloadElem = document.createElement('a');
            const url = URL.createObjectURL(zipBuffer);

            downloadElem.href = url;
            downloadElem.download = zipFileName;

            document.body.appendChild(downloadElem);
            downloadElem.click();
            document.body.removeChild(downloadElem);
            window.URL.revokeObjectURL(url);
        }
    } catch (error) {
        console.error('Erro ao baixar XMLs e converter para PDF:', error);
    }
};

  

  const baixarSelecao: DownloadFunction = async ({ porEmail, email }) => {
    if (porEmail) {
      if (!email) {
        toast.error('Email invalido!')
        return
      }
      const pedido = await handleRequest(
        apiSelecaoXmlEmail({
          email,
          filtros: { chaves: selecaoChave as string[] },
        }),
        {
          success: 'Email enviado!',
          loading: 'Preparando email...',
        }
      ).then(() => mutate())
      console.log(pedido)
    } else {
      const arquivo = await handleRequest(
        apiSelecaoXmlDownload({
          chaves: selecaoChave as string[],
        }),
        {
          success: 'Pronto',
          loading: 'Preparando download...',
        }
      ).then(v => {
        mutate()
        return v
      })

      const fileName = `CNPJ-${empresa?.cnpj} ${format(
        new Date(),
        `dd/MM/yyyy HH'h' mm'm'`
      )}.zip`

      if (!!window.showSaveFilePicker) {
        const fileHandle = await window
          .showSaveFilePicker({
            excludeAcceptAllOption: true,
            types: [
              {
                description: 'Arquivo zipado',
                accept: { 'application/zip': ['.zip'] },
              },
            ],
            suggestedName: fileName,
          })
          .catch(err => {
            console.log(err)
            fileDownload(arquivo.data, fileName)
          })
        if (fileHandle) {
          const writable = await fileHandle.createWritable()
          await writable.write(arquivo.data)
          await writable.close()
        }
      } else {
        fileDownload(arquivo.data, fileName)
      }
    }
  }

  const baixarUmXml = async (chave: string, modelo: string, emissao: Date) => {
    try {
      const arquivo = await handleRequest(apiUmXml({ chave, modelo: modelo }), {
        success: 'Pronto',
        loading: 'Preparando download...',
      }).then(v => {
        mutate();
        return v;
      });
  
      const fileName = `${chave}.xml`;
  
      if (!!window.showSaveFilePicker) {
        const fileHandle = await window
          .showSaveFilePicker({
            excludeAcceptAllOption: true,
            types: [
              {
                description: 'Arquivo xml',
                accept: { 'application/xml': ['.xml'] },
              },
            ],
            suggestedName: fileName,
          })
          .catch(err => {
            console.log(err);
            fileDownload(arquivo.data, fileName);
          });
        if (fileHandle) {
          const writable = await fileHandle.createWritable();
          await writable.write(arquivo.data);
          await writable.close();
        }
      } else {
        fileDownload(arquivo.data, fileName);
      }
    } catch (error) {
      console.error('Erro ao baixar XML:', error);
    }
  };

 const baixarUmXmlEConverterParaPdf = async (chave: string, modelo: string, emissao: Date) => {
  try {
    const xmlResponse = await handleRequest(apiUmXml({ chave, modelo }), {
      success: 'Pronto',
      loading: 'Preparando documento...',
    });

    const textoXml = await xmlResponse.data.text();

    const pdfConversionResponse = await fetch('https://ws.meudanfe.com/api/v1/get/nfe/xmltodanfepdf/API', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: textoXml,
    });

    if (pdfConversionResponse.ok) {
      let pdfContent = await pdfConversionResponse.text();

      pdfContent = pdfContent.replace(/^"|"$/g, '');

      // Retornar o conteúdo do PDF
      return pdfContent;
    } else {
      throw new Error(`Falha na requisição para a API de conversão. Código de status: ${pdfConversionResponse.status}`);
    }
  } catch (error) {
    console.error('Erro ao baixar XML e converter para PDF:', error);
    throw error;
  }
  };

  const baixarPDF = async (chave: string, modelo: string, emissao: Date) => {
    try {
      const xmlResponse = await handleRequest(apiUmXml({ chave, modelo }), {
        success: 'Pronto',
        loading: 'Preparando documento...',
      });
  
      const textoXml = await xmlResponse.data.text();
  
      const pdfConversionResponse = await fetch('https://ws.meudanfe.com/api/v1/get/nfe/xmltodanfepdf/API', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: textoXml,
      });
  
      if (pdfConversionResponse.ok) {
        let pdfContentBase64 = await pdfConversionResponse.text();
  
        // Remova as aspas do início e do fim
        pdfContentBase64 = pdfContentBase64.replace(/^"|"$/g, '');
  
        // Adicionar o prefixo ao conteúdo do PDF
        const pdfDataUri = `data:application/pdf;base64,${pdfContentBase64}`;
  
        const link = document.createElement('a');
        link.href = pdfDataUri;
        link.download = `${chave}.pdf`;
        document.body.appendChild(link);
        link.click();
      } else {
        console.error(`Falha na requisição para a API de conversão. Código de status: ${pdfConversionResponse.status}`);
      }
    } catch (error) {
      console.error('Erro ao baixar XML e converter para PDF:', error);
    }
  };


  
const baixarVariosXmls = async (chaves: (string | any)[], modelo: any, emissao: Date) => {
  try {
    const arquivos = await Promise.all(chaves.map(chave => apiUmXml({ chave, modelo })));

    const zip = new JSZip();

    arquivos.forEach(({ data }, index) => {
      const chave = chaves[index];
      const fileName = `XMLs-${chave}.xml`;
      zip.file(fileName, data);
    });

    const zipBuffer = await zip.generateAsync({ type: 'blob' });

    const zipFileName = `CNPJ-${empresa?.cnpj} ${format(emissao, 'dd-MM-yyyy')} ArquivosXML.zip`;

    // Salvar o arquivo zip usando a biblioteca file-saver
    saveAs(zipBuffer, zipFileName);
  } catch (error) {
    console.error('Erro ao baixar XMLs:', error);
  }
};

  
  // Função para baixar o arquivo diretamente
  const baixarArquivo = async (zipBuffer: Blob, zipFileName: string) => {
    fileDownload(zipBuffer, zipFileName);
  };
  

  const baixarVariosPDFs = async (chaves: (string | any)[], modelo: string, emissao: Date) => {
    try {
      const pdfPromises = chaves.map(async (chave) => {
        const xmlResponse = await apiUmXml({ chave, modelo });
        const textoXml = await xmlResponse.data.text();
  
        const pdfConversionResponse = await fetch('https://ws.meudanfe.com/api/v1/get/nfe/xmltodanfepdf/API', {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
          },
          body: textoXml,
        });
  
        if (pdfConversionResponse.ok) {
          let pdfContentBase64 = await pdfConversionResponse.text();

          pdfContentBase64 = pdfContentBase64.replace(/^"|"$/g, '');
  
          return {
            chave,
            pdfContentBase64,
          };
        } else {
          console.error(`Falha na requisição para a API de conversão. Código de status: ${pdfConversionResponse.status}`);
          return null;
        }
      });
  
      const pdfs = await Promise.all(pdfPromises.filter(Boolean)) as { chave: string; pdfContentBase64: string }[];
  
      if (pdfs.length === 0) {
        console.error('Nenhum PDF gerado.');
        return;
      }
  
      const zip = new JSZip();

      pdfs.forEach(({ chave, pdfContentBase64 }) => {
        const pdfData = Uint8Array.from(atob(pdfContentBase64), (c) => c.charCodeAt(0));
  
        zip.file(`${chave}.pdf`, pdfData);
      });
  
      const zipBuffer = await zip.generateAsync({ type: 'blob' });
  
      const zipFileName = `CNPJ-${empresa?.cnpj} ${format(emissao, 'dd-MM-yyyy')} ArquivosPDF.zip`;
  
      if (!!window.showSaveFilePicker) {
        const downloadDirectly = baixarArquivo(zipBuffer, zipFileName);
  
        const saveFilePicker = window.showSaveFilePicker({
          excludeAcceptAllOption: true,
          types: [
            {
              description: 'Arquivo zip',
              accept: { 'application/zip': ['.zip'] },
            },
          ],
          suggestedName: zipFileName,
        }).then(async fileHandle => {
          const writable = await fileHandle.createWritable();
          await writable.write(zipBuffer);
          await writable.close();
        }).catch(error => {
        });
  
        await Promise.all([downloadDirectly, saveFilePicker]);
      } else {
        await baixarArquivo(zipBuffer, zipFileName);
      }
    } catch (error) {
      // console.error('Erro ao baixar XMLs e converter para PDF:', error);
    }
  };
  

  const baixarXmlsEPDFsZipados = async (chaves: (string | any)[], modelo: any, emissao: Date) => {
    try {
      const arquivosXml = await Promise.all(chaves.map(chave => apiUmXml({ chave, modelo })));
  
      const zip = new JSZip();
  
      arquivosXml.forEach(({ data }, index) => {
        const chave = chaves[index];
        const fileName = `XML-${chave}.xml`;
        zip.file(fileName, data);
      });
  
      // Parte 2: Adiciona os arquivos PDF ao ZIP
      const pdfPromises = chaves.map(async (chave) => {
        const xmlResponse = await apiUmXml({ chave, modelo });
        const textoXml = await xmlResponse.data.text();
  
        const pdfConversionResponse = await fetch('https://ws.meudanfe.com/api/v1/get/nfe/xmltodanfepdf/API', {
          method: 'POST',
          headers: {
            'Content-Type': 'text/plain',
          },
          body: textoXml,
        });
  
        if (pdfConversionResponse.ok) {
          let pdfContentBase64 = await pdfConversionResponse.text();
          pdfContentBase64 = pdfContentBase64.replace(/^"|"$/g, '');
  
          return {
            chave,
            pdfContentBase64,
          };
        } else {
          console.error(`Falha na requisição para a API de conversão. Código de status: ${pdfConversionResponse.status}`);
          return null;
        }
      });
  
      const pdfs = await Promise.all(pdfPromises.filter(Boolean)) as { chave: string; pdfContentBase64: string }[];
  

      pdfs.forEach(({ chave, pdfContentBase64 }) => {
        const pdfData = Uint8Array.from(atob(pdfContentBase64), (c) => c.charCodeAt(0));
        zip.file(`PDF-${chave}.pdf`, pdfData);
      });
  
      const zipBuffer = await zip.generateAsync({ type: 'blob' });
  
      const zipFileName = `CNPJ-${empresa?.cnpj} ${format(emissao, 'dd-MM-yyyy')} ArquivosXMLePDFs.zip`;
  
      if (!!window.showSaveFilePicker) {
        const downloadDirectly = baixarArquivo(zipBuffer, zipFileName);
  
        const saveFilePicker = window.showSaveFilePicker({
          excludeAcceptAllOption: true,
          types: [
            {
              description: 'Arquivo zip',
              accept: { 'application/zip': ['.zip'] },
            },
          ],
          suggestedName: zipFileName,
        }).then(async fileHandle => {
          const writable = await fileHandle.createWritable();
          await writable.write(zipBuffer);
          await writable.close();
        }).catch(error => {
        });
  
        await Promise.all([downloadDirectly, saveFilePicker]);

      } else {
        await baixarArquivo(zipBuffer, zipFileName);
      }
    } catch (error) {
      // console.error('Erro ao baixar XMLs e converter para PDF:', error);
    }
  };
  
  
  return { baixarSelecao, baixarTodasPaginas, baixarUmXml, baixarUmXmlEConverterParaPdf, baixarPDF, baixarVariosXmls, baixarVariosPDFs, baixarXmlsEPDFsZipados, baixarTodosXmlsEConverterParaPdf, baixarTodosXmlsEPdfs };
}

