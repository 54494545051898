import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import {
  Key,
  SorterResult,
  TableCurrentDataSource,
}
  from 'antd/lib/table/interface'
import { BaixadoEmButton } from 'components/BaixadoEmButton'
import { DownloadDocButton } from 'components/DownloadDocButton'
import { colunasMostradasState } from 'components/EsconderColunas'
import { ClickCopiar } from 'components/Tabela/ClickCopiar'
import { TagModelo } from 'components/Tabela/TagModelo'
import { TagStatus } from 'components/Tabela/TagStatus'
import { compareAsc, format } from 'date-fns'
import { useDownload } from 'hooks/useDownload'
import React, { useCallback, useMemo } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Documento } from '../generated'
import { stringToData } from '../lib/utils'
import { paginacaoState, sortState } from '../store/paginacaoState'
import { filtroModeloState } from 'store/filtrosState'

export const useTabela = <T extends Documento>() => {
  const { baixarUmXml, baixarUmXmlEConverterParaPdf, baixarPDF } = useDownload()
  
  let arrayModelo = useRecoilValue(filtroModeloState)
  let modelo = arrayModelo[0]
  


  const colunas: ColumnsType<T> = useMemo(
    () => [
      {
        title: 'Tipo',
        key: 'modelo',
        dataIndex: 'modelo',
        render: (value: string) => <TagModelo modelo={value} />,
        shouldCellUpdate: () => false,
        width: 100,
      },
      {
        title: 'Código',
        key: 'codigo',
        dataIndex: 'codigo',
        width: 100,
        shouldCellUpdate: () => false,
        sorter: (a, b) => Number(a.codigo) - Number(b.codigo),
        // sortDirections: ['descend'],
      },
      {
        title: 'Valor',
        key: 'valor',
        dataIndex: 'valor',
        width: 120,
        shouldCellUpdate: () => false,
        render: (value: number) =>
          value ? (
            <span className='break-normal'>
              {value.toLocaleString('pt-BR', {
                currency: 'BRL',
                style: 'currency',
              })}
            </span>
          ) : null,
        sorter: (a, b) => Number(a.valor) - Number(b.valor),
      },
      {
        title: 'Série',
        key: 'serie',
        dataIndex: 'serie',

        shouldCellUpdate: () => false,
        sorter: (a, b) => 1,
        width: 80,
        render: (value: number | undefined) =>
          value?.toString().padStart(3, '0'),
      },
      {
        title: 'Emissão',
        key: 'emissao',
        dataIndex: 'emissao',
        shouldCellUpdate: () => false,
        render: (value: string) => (
          <span className='break-normal'>
            {format(stringToData(value), 'dd/MM/yyyy')}
          </span>
        ),
        sorter: (a, b) =>
          compareAsc(stringToData(a.emissao), stringToData(b.emissao)),
        width: 112,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',

        shouldCellUpdate: () => false,
        sorter: (a, b) => 1,
        render: (value: string) => <TagStatus status={value} />,
        width: 112,
      },
      {
        ellipsis: true,
        shouldCellUpdate: () => false,
        render: (value: string, record: any) => {
          if (record.status !== 'I') {
            return (<ClickCopiar texto={value} />)
          }

        },
        // width: 200,
        title: 'Chave',
        key: 'chave',
        dataIndex: 'chave',
      },
      {
        title: 'Baixado em',
        width: 140,
        key: 'data',
        dataIndex: 'data',
        // shouldCellUpdate: () => false,
        render: (value: string) => <BaixadoEmButton data={value} />,
      },
      {
        title: 'Acões',
        width: modelo && !(modelo === 'nfe-emitida' || modelo === 'nfe-terceiro') ? 70 : 110, // Controla largura da celula de ações
        key: 'acoes',
        dataIndex: 'acoes',
        shouldCellUpdate: () => false,
        render: (_value: any, record: Documento) => (
          <DownloadDocButton
            baixarUmXml={baixarUmXml}
            baixarUmXmlEConverterParaPdf={baixarUmXmlEConverterParaPdf}
            baixarPDF={baixarPDF}
            chave={record.chave}
            modelo={record.modelo}
            emissao={record.emissao}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modelo]
  )

  const colunasMostradas = useRecoilValue(colunasMostradasState)
  const colunasFiltradas = useMemo(
    () => colunas.filter(col => colunasMostradas.includes(col.key as string)),
    [colunas, colunasMostradas]
  )

  const [paginacao, setPaginacao] = useRecoilState(paginacaoState)
  const setSort = useSetRecoilState(sortState)

  const handleTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      _filters: Record<string, (Key | boolean)[] | null>,
      sorter: SorterResult<T> | SorterResult<T>[],
      extra: TableCurrentDataSource<T>
    ) => {
      const { current = 1, total = 0, pageSize = 7 } = pagination
      setPaginacao({ current, total, pageSize })
      if (extra.action === 'sort') {
        const { column, field, order } = sorter as SorterResult<T>
        if (column) {
          const desc = order === 'descend'
          setSort({ coluna: field?.toString() || '', desc })
        } else {
          setSort(null)
        }
      }
    },
    [setPaginacao, setSort]
  )

  return { handleTableChange, colunasFiltradas, paginacao }
}
