import { Worker, Viewer, LocalizationMap } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { printPlugin } from '@react-pdf-viewer/print';
import { Image, Modal } from 'antd';
import pt_PT from '@react-pdf-viewer/locales/lib/pt_PT.json';

const PdfViewerComponent = (props: {
  pdfContent: any;
  visiblePdfViewer: boolean;
  onClose: () => void;
}) => {

  const layoutPluginInstance = defaultLayoutPlugin();
  const printPluginInstance = printPlugin();


  const pdfDataUri = `data:application/pdf;base64,${props.pdfContent}`;
  
  const logo = `https://media.licdn.com/dms/image/C4E0BAQEHbyKeUsdcFA/company-logo_200_200/0/1630648935973/success_sistemas__informatica_logo?e=2147483647&v=beta&t=oQc-w8TmAWMOpqWNEjAj65jvf0YEs1rD6ELzT7W5x5w`

  return (
    <>
      <Modal
        title={<Image 
                src={logo} 
                height={35} 
                preview={false} 
                /> }
        style={{ top: 25 }}
        visible={props.visiblePdfViewer}
        onCancel={props.onClose}
        footer={null}
        width={1050}
        bodyStyle={{ height: '85vh' }}
      >
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
          <Viewer fileUrl={pdfDataUri} localization={pt_PT as unknown as LocalizationMap} plugins={[layoutPluginInstance, printPluginInstance]} />
        </Worker>
      </Modal>
    </>
  );
};

export default PdfViewerComponent;
