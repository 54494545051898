import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Input } from 'antd'
import { useEffect, useState } from 'react'
import { MdError } from 'react-icons/md'
import { useClientes } from '../../../hooks/useClientes'
import { apiGetEmpresas } from '../../../lib/api/api'
import { handleError } from '../../../lib/errorHandler'
import { Loading } from '../Loading'
import { ItemListaCliente } from './ItemListaCliente'

function mapAsync<T, U>(
  array: T[],
  callbackfn: (value: T, index: number, array: T[]) => Promise<U>
): Promise<U[]> {
  return Promise.all(array.map(callbackfn))
}

async function filterAsync<T>(
  array: T[],
  callbackfn: (value: T, index: number, array: T[]) => Promise<boolean>
): Promise<T[]> {
  const filterMap = await mapAsync(array, callbackfn)
  return array.filter((value, index) => filterMap[index])
}

export const ListaClientes = () => {
  const { clientes, error, loading } = useClientes()

  const modal = useDisclosure()

  const [inputCNPJ, setInputCNPJ] = useState('')
  const [clientesFiltrados, setClientesFiltrados] = useState(clientes)

  useEffect(() => {
    localStorage.removeItem('token')
  }, []);

  useEffect(() => {
    let active = true
    calcFilter()
    return () => {
      active = false
    }

    async function calcFilter() {
      if (!inputCNPJ || !clientes) {
        setClientesFiltrados(clientes)
        return
      }

      const filtrados = await filterAsync(clientes, async cli => {
        if (cli.cnpj.includes(inputCNPJ)) return true
        if (!cli.configurado) return false

        try {
          const empresas = await apiGetEmpresas(cli.codigo)
          if (!empresas || !empresas.data) return false
          const cnpjEmpresas = empresas.data.map(e => e.cnpj)
          return cnpjEmpresas.some(cnpj => cnpj.includes(inputCNPJ))
        } catch (err) {
          return false
        }
      })

      if (!active) {
        return
      }

      setClientesFiltrados(filtrados)
    }
  }, [clientes, inputCNPJ])

  if (loading) return <Loading />

  if (error)
    return (
      <Alert my={4} fontSize='lg' status='error'>
        <AlertIcon />
        <AlertTitle mr={3}>Erro</AlertTitle>
        <AlertDescription>{handleError(error)}</AlertDescription>
      </Alert>
    )

  return (
    <>
      <Box pt={1} pb={4}>
        <Box maxW='8xl' mx='auto' px={{ sm: 3, lg: 5 }}>
          <Box bg='white' shadow='md' overflow='hidden' rounded={{ sm: 'md' }}>
            <Flex
              bg='white'
              px={{ base: 4, sm: 6 }}
              py={5}
              borderBottomWidth={1}
              borderColor='gray.200'
              justify='space-between'
              direction={{ base: 'column', md: 'row' }}
            >
              <Heading size='lg' mb={{ base: 3, md: 0 }}>
                Meus Clientes
              </Heading>
              <Box w={{ base: 'full', md: 'sm' }}>
                <Input.Search
                  onSearch={setInputCNPJ}
                  placeholder='Filtrar por CNPJ'
                />
              </Box>
            </Flex>
            <Stack
              spacing={1}
              divider={<StackDivider borderColor='gray.200' />}
            >
              {clientesFiltrados &&
                clientesFiltrados.map(cliente =>
                  cliente.configurado ? (
                    <ItemListaCliente key={cliente.codigo} cliente={cliente} />
                  ) : (
                    <Box
                      key={cliente.codigo}
                      cursor='pointer'
                      _hover={{ bg: 'gray.100' }}
                      onClick={modal.onOpen}
                    >
                      <ItemListaCliente
                        key={cliente.codigo}
                        cliente={cliente}
                      />
                    </Box>
                  )
                )}
            </Stack>
          </Box>
        </Box>
      </Box>
      <Modal isOpen={modal.isOpen} size='xl' onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader d='flex' alignItems='center'>
            <Icon as={MdError} color='red.500' mr={2} boxSize='28px' />
            <Text>Aviso: sincronismo não configurado!</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <Text>
                Caro contador, não foi encontrado no nosso registro as
                informações de conexão desse cliente.
              </Text>
              <Text>
                Isso significa que esse cliente não fez o processo de
                configuração do sincronismo, Se você tem autorização do cliente
                e deseja utilizar o Portal Contábil, por favor peça ao seu
                cliente que entre em contato com nosso suporte para realizar os
                seguintes passos.
              </Text>
              <List>
                <ListItem>
                  1. Configurar a Conexão de Dados Fiscais no painel
                  utilitários.
                </ListItem>
                <ListItem>
                  2. Configurar o Sincronismo de Dados Fiscais.
                </ListItem>
              </List>
              <Text>
                Por gentileza repasse essa informação ao seu cliente.{' '}
              </Text>
              <Text fontWeight='bold' fontSize='lg'>
                A Success Sistemas agradece a compreensão.
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' onClick={modal.onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
