import { DatePicker, Select, Space } from 'antd'
import moment, { Moment } from 'moment'
import React, { useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useResponsivo } from '../../hooks/useResponsivo'
import { filtroPeriodoState } from '../../store/filtrosState'
import { NovoSeletorPeriodo } from './NovoSeletorPeriodo'

const formatoData = 'DD/MM/YYYY'

export const SeletorPeriodo = () => {
  const [{ inicio, fim }, setPeriodo] = useRecoilState(filtroPeriodoState)

  const onChange = (values: [Moment | null, Moment | null] | null) => {
    if (values !== null) {
      const [inicioAlvo, fimAlvo] = values
      if (inicioAlvo && fimAlvo) {
        setPeriodo({ inicio: inicioAlvo.toDate(), fim: fimAlvo.toDate() })
      }
    }
  }

  return (
    <DatePicker.RangePicker
      // className='w-full sm:w-64 md:w-72'
      value={[moment(inicio), moment(fim)]}
      popupStyle={{ zIndex: 2000 }}
      onChange={onChange}
      format={formatoData}
    />
  )
}

export const tipoPeriodoState = atom<'mes' | 'periodo'>({
  default: 'mes',
  key: 'tipoPeriodo',
})

export const SeletorMesPeriodo = () => {
  const [tipo, setTipo] = useRecoilState(tipoPeriodoState)

  return (
    <Space>
      <Select
        dropdownStyle={{ zIndex: 2000 }}
        value={tipo}
        className='w-24'
        onChange={setTipo}
      >
        <Select.Option value='mes'>Mês</Select.Option>
        <Select.Option value='periodo'>Período</Select.Option>
      </Select>
      {tipo === 'mes' && <SeletorMes />}
      {tipo === 'periodo' && <SeletorPeriodo />}
    </Space>
  )
}

export const SeletorMes = () => {
  const [{ inicio }, setPeriodo] = useRecoilState(filtroPeriodoState)

  const onSelecionar = (d: Moment) => {
    const primeiroDia = d.startOf('month').toDate()
    const ultimoDia = d.endOf('month').toDate()
    setPeriodo({ inicio: primeiroDia, fim: ultimoDia })
  }

  useEffect(() => {
    onSelecionar(moment(inicio))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DatePicker
      popupStyle={{ zIndex: 2000 }}
      picker='month'
      format='MMM-YYYY'
      value={moment(inicio)}
      onSelect={onSelecionar}
    />
  )
}

export const SeletorPeriodoResponsivo = () => {
  const { lg } = useResponsivo()
  return lg ? <NovoSeletorPeriodo /> : <SeletorPeriodo />
}
