import {
  Button,
  Checkbox,
  CheckboxGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Stack,
  Tooltip,
} from '@chakra-ui/react'
import React from 'react'
import { ReactText } from 'react'
import { FaColumns } from 'react-icons/fa'
import { useToggle } from 'react-use'
import { atom, useRecoilState } from 'recoil'

export const colunasMostradasState = atom({
  key: 'colunasMostradas',
  default: [
    'modelo',
    'data',
    'status',
    'serie',
    'valor',
    'chave',
    'codigo',
    'emissao',
    'acoes',
  ],
})

const todasColunas = [
  {
    label: 'Código',
    value: 'codigo',
  },
  {
    label: 'Tipo',
    value: 'modelo',
  },
  {
    label: 'Valor (R$)',
    value: 'valor',
  },
  {
    label: 'Série',
    value: 'serie',
  },
  {
    label: 'Emissão',
    value: 'emissao',
  },
  {
    label: 'Status',
    value: 'status',
  },
  {
    label: 'Chave',
    value: 'chave',
  },
  {
    label: 'Baixado em',
    value: 'data',
  },
  {
    label: 'Acões',
    value: 'acoes',
  },
]

export const EsconderColunas = () => {
  const [open, toggle] = useToggle(false)

  const [colunasMostradas, setColunasMostradas] = useRecoilState(
    colunasMostradasState
  )

  const onChange = (values: ReactText[]) => {
    setColunasMostradas(values as string[])
  }

  return (
    <div>
      <Tooltip label='Mostrar/Esconder colunas'>
        <IconButton
          aria-label='mostrar colunas'
          onClick={toggle}
          variant='ghost'
          fontSize='2xl'
          colorScheme='blue'
          icon={<FaColumns />}
        />
      </Tooltip>
      <Drawer isOpen={open} placement='right' onClose={() => toggle(false)}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Mostrar Colunas</DrawerHeader>

          <DrawerBody>
            <CheckboxGroup value={colunasMostradas} onChange={onChange}>
              <Stack spacing={3}>
                {todasColunas.map(coluna => (
                  <Checkbox key={coluna.value} value={coluna.value}>
                    {coluna.label}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </DrawerBody>

          <DrawerFooter>
            <Button onClick={() => toggle(false)} colorScheme='blue'>
              Fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  )
}
