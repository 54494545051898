import { BarraDocumentos } from 'components/BarraDocumentos'
import { TabelaDocumentos } from 'components/Tabela/TabelaDocumentos'
import { useTodosDocumentos } from 'hooks/useTodosDocumentos'
import { useEffect, useMemo} from 'react'
import { useSetRecoilState } from 'recoil'
import {
  filtroChaveState,
  filtroCodigoState,
  filtroIgnorarBaixadosState,
  filtroModeloState,
  filtroPeriodoState,
  filtroSerieState,
  filtroStatusState,
  getPeriodo,
} from '../../store/filtrosState'

export const TodosPage = () => {
  const { documentos, isLoading } = useTodosDocumentos()

  const setModelo = useSetRecoilState(filtroModeloState)
  const setStatus = useSetRecoilState(filtroStatusState)
  const setPeriodo = useSetRecoilState(filtroPeriodoState)
  const setSerie = useSetRecoilState(filtroSerieState)
  const setCodigo = useSetRecoilState(filtroCodigoState)
  const setChave = useSetRecoilState(filtroChaveState)
  const setIgnorarBaixados = useSetRecoilState(filtroIgnorarBaixadosState)

  const data = useMemo(() => documentos && documentos.data, [documentos])

  useEffect(() => {
    setModelo([])
    setStatus([])
    setPeriodo({
      inicio: getPeriodo().primeiroDia,
      fim: getPeriodo().ultimoDia,
    })
    setSerie('')
    setCodigo('')
    setChave('')
    setIgnorarBaixados(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <BarraDocumentos valorTotal={documentos?.valorTotal} filtroTipo />
      <TabelaDocumentos isLoading={isLoading} data={data} />
    </div>
  )
}
