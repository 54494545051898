import {
  Box,
  Button,
  Center,
  Heading,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { FaUserLock } from 'react-icons/fa'
import { useContador } from '../../hooks/useContador'
import { SuccessLogoNome } from '../Logo/SuccessLogo'

export const ContadorInfo = () => {
  const { logout, loading, contador } = useContador()

  if (loading)
    return (
      <Center pt={4}>
        <Spinner />
      </Center>
    )

  return (
    <>
      <Stack
        direction={['column', 'column', 'row']}
        justifyContent='space-between'
        alignItems={{ base: 'flex-start', md: 'center' }}
        p={3}
        bg='blue.100'
      >
        <SuccessLogoNome maxW='44' mx='2' />

        <Heading fontWeight='medium' size='md' fontFamily='body'>
          {contador?.razao}
        </Heading>
        <Box>
          <Text color='GrayText'>
            Email:{' '}
            <Text as='span' d='inline' color='black' fontWeight='bold'>
              {contador?.email}
            </Text>
          </Text>
          <Text color='GrayText'>
            CGC:{' '}
            <Text as='span' d='inline' color='black' fontWeight='bold'>
              {contador?.cgc}
            </Text>
          </Text>
          <Text color='GrayText'>
            Usuário:{' '}
            <Text as='span' d='inline' color='black' fontWeight='bold'>
              {contador?.usuario}
            </Text>
          </Text>
        </Box>

        <Button
          size='sm'
          colorScheme='blue'
          leftIcon={<FaUserLock />}
          onClick={logout}
        >
          Desconectar
        </Button>
      </Stack>
    </>
  )
}
