import { Box, Button, Center, Text } from '@chakra-ui/react'
import { Result } from 'antd'
import React, { ReactNode } from 'react'
import { FaRedo } from 'react-icons/fa'
import { FiLogOut } from 'react-icons/fi'
import { useUsuario } from '../../hooks/useUsuario'
import { AlertaErro } from '../Basico/AlertaErro'
import { ContadorInfo } from '../Contador/ContadorInfo'
import { ContratoInfo } from './ContratoInfo'
import { Loading } from './Loading'
import { Login } from './Login'

type Props = {
  children: ReactNode
}

export const CheckUser = (props: Props) => {
  const {
    user,
    loading,
    login,
    error,
    mutate,
    logout,
    isValidating,
  } = useUsuario()

  if (loading) return <Loading />

  if (error && !error.response) {
    return (
      <div>
        <ContadorInfo />
        <ContratoInfo />
        <div data-testid='semConexaoErro'>
          <Result
            status='error'
            title='Sem conexão.'
            subTitle='Nao conseguimos conectar ao banco de dados, tente novamente mais tarde.'
            extra={[
              <Button
                key='1'
                isLoading={isValidating}
                onClick={() => mutate()}
                colorScheme='blue'
                leftIcon={<FaRedo />}
              >
                Tentar Novamente
              </Button>,
            ]}
          />
        </div>
      </div>
    )
  }

  if (user) {
    if (user.bloqueio === 'S')
      return (
        <div>
          <ContadorInfo />
          <ContratoInfo />
          <AlertaErro
            my={6}
            titulo='Aviso!'
            descricao='Seu usuário consta como bloqueado no Sistema Success do cliente, para acessar por favor entre em contato com seu cliente e peça o mesmo para remover o bloqueio do seu usuário.'
          />
        </div>
      )

    if (!user.acsweb)
      return (
        <div>
          <ContadorInfo />
          <ContratoInfo />
          <AlertaErro
            my={6}
            titulo='Aviso!'
            descricao={
              <Box color='gray.700' textAlign='left'>
                <Text>
                  Seu usuário não tem permissão para acesso Web. Para corrigir
                  isso entre em contato com seu cliente e o oriente a fazer a
                  liberação da área de acesso para o seu usuário.{' '}
                </Text>
                <Text>
                  A liberação pode ser feita através do{' '}
                  <Text as='span' mx={1} fontWeight='bold'>
                    Módulo Utilitários {'>'} Lançamentos {'>'} Áreas de acesso{' '}
                  </Text>
                </Text>
                <Text>
                  Basta configurar o campo{' '}
                  <Text as='span' fontWeight='bold'>
                    Acesso Web
                  </Text>{' '}
                  para o seu usuário, mostre essa mensagem ao seu cliente para
                  que ele possa liberar seu acesso.
                </Text>
              </Box>
            }
          />
          <Center w='full'>
            <Button rightIcon={<FiLogOut />} onClick={logout} colorScheme='red'>
              Trocar Usuário
            </Button>
          </Center>
        </div>
      )

    return <>{props.children}</>
  }

  return <Login onEnviar={login} />
}
