import { Alert, Button, Form, Input, Radio, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useState } from 'react'

export interface DownloadFunctionOptions {
  selecao: string[];
  modelo: string;
  porEmail?: boolean;
  email?: string;
}
export type DownloadFunction = (opcoes: {
  porEmail: boolean
  email?: string
}) => Promise<void>

export type BaixarVariosXmlsParams = {
  selecaoChave: string[];
  modelo: string;
  emissao: Date;
};

export type Props = {
  baixar: DownloadFunction
  loading: boolean
  isOpen: boolean
  numeroDocs: number
  onClose: () => void
}

export const AlertNumeroDocs = (props: { numeroDocs: number }) => (
  <Alert
    banner
    message={`${props.numeroDocs} documentos selecionados`}
    type='warning'
    showIcon
    className='mr-4'
  />
)

export const MetodoDownloadContainer = (props: {
  onProsseguir: any
  onProsseguirPDF: any
  onProsseguirAmbos: any
  onProsseguirEmail: any
  onClose: boolean
  onProsseguirTodosPDF: any
  onProsseguirTodosPDFeXML: any
  modelo: string[]


}) => {
  const [form] = useForm()

  const [metodoIsEmail, setMetodoIsEmail] = useState(false)
  const [selectedOption, setSelectedOption] = useState('');

  const prosseguir = async () => {

    if (metodoIsEmail) {

      form.validateFields();
      const email = form.getFieldValue('email');

      if (!email) return;

      switch (selectedOption) {
        case 'receivePDFByEmail':
          await props.onProsseguirEmail({ porEmail: true, email: email });
          break;

        case 'receiveXMLByEmail':
          await props.onProsseguirEmail({ porEmail: true, email: email });
          break;

        case 'downloadPDFandXML':
          await props.onProsseguirAmbos({ porEmail: false });
          break;

        default:
          break;
      }
    } else {
      switch (selectedOption) {
        case 'downloadPDF':
          await props.onProsseguirPDF({ porEmail: false });
          break;

        case 'downloadXML':
          await props.onProsseguir({ porEmail: false });
          break;

        case 'downloadPDFandXML':
          await props.onProsseguirAmbos({ porEmail: false });
          break;

        case 'downloadPdfTodos':
          await props.onProsseguirTodosPDF({ porEmail: false });
          break;

        case 'downloadAllXmlPdf':
          await props.onProsseguirTodosPDFeXML({ porEmail: false });
          break;

        default:
          break;
      }
    }
  };

  return (
    <Space direction='vertical' size='large' className='w-full' align='center'>
      <p className='font-bold text-xl'>Como deseja receber os arquivos?</p>

      {(props.modelo[0] === 'nfe-emitida' || props.modelo[0] === 'nfe-terceiro') ? (
        <>
          {props.onClose ? (
            <Radio.Group
              value={selectedOption}
              onChange={e => setSelectedOption(e.target.value)}
              size='large'
            >
              <Radio.Button
                value={'downloadXML'}
                onClick={() => { setMetodoIsEmail(false); }}
              >
                Fazer Download XMLs
              </Radio.Button>
              <Radio.Button
                value={'downloadAllXmlPdf'}
                onClick={() => { setMetodoIsEmail(false); }}
              >
                Fazer Download Todos XMLs e DANFEs
              </Radio.Button>
              <Radio.Button
                value={'downloadPdfTodos'}
                onClick={() => { setMetodoIsEmail(false); }}
              >
                Fazer Download DANFEs
              </Radio.Button>
            </Radio.Group>
          ) : ( 
            <Radio.Group
              value={selectedOption}
              onChange={e => setSelectedOption(e.target.value)}
              size='large'
            >
              <Radio.Button
                value={'downloadXML'}
                onClick={() => { setMetodoIsEmail(false); }}
              >
                Fazer Download XML
              </Radio.Button>
              <Radio.Button
                value={'receiveXMLByEmail'}
                onClick={() => { setMetodoIsEmail(true); }}
              >
                Receber XML por email
              </Radio.Button>
              <Radio.Button
                value={'downloadPDFandXML'}
                onClick={() => { setMetodoIsEmail(false); }}
              >
                Fazer Download XML e DANFE
              </Radio.Button>
              <Radio.Button
                value={'downloadPDF'}
                onClick={() => { setMetodoIsEmail(false); }}
              >
                Fazer Download DANFE
              </Radio.Button>
            </Radio.Group>
          )}
        </>
      ) : (
        <Radio.Group
          value={selectedOption}
          onChange={e => setSelectedOption(e.target.value)}
          size='large'
        >
          <Radio.Button
                value={'downloadXML'}
                onClick={() => { setMetodoIsEmail(false); }}
              >
                Fazer Download XMLs
              </Radio.Button>
              <Radio.Button
                value={'receiveXMLByEmail'}
                onClick={() => { setMetodoIsEmail(true); }}
              >
                Receber XML por email
              </Radio.Button>
          {/* Adicione mais botões conforme necessário */}
        </Radio.Group>
      )}


      {/* <Radio.Button
          value={'receivePDFByEmail'}
          onClick={() => { setMetodoIsEmail(true); }}
        >
          Receber PDF por email
        </Radio.Button></Radio.Group>} */}

      {/* <Radio.Group
        value={selectedOption}
        onChange={e => setSelectedOption(e.target.value)}
        size='large'
      >
        <Radio.Button
          value={'downloadXML'}
          onClick={() => { setMetodoIsEmail(false); }}
        >
          Fazer Download XML
        </Radio.Button>
        <Radio.Button
        value={'receiveXMLByEmail'}
          onClick={() => { setMetodoIsEmail(true); }}
        >
          Receber XML por email
        </Radio.Button>
        <Radio.Button
          value={'downloadPDFandXML'}
          onClick={() => { setMetodoIsEmail(false); }}
        >
          Fazer Download XML e PDF
        </Radio.Button>
        <Radio.Button
          value={'downloadPDF'}
          onClick={() => { setMetodoIsEmail(false); }}
        >
          Fazer Download PDF
        </Radio.Button>
        <Radio.Button
          value={'receivePDFByEmail'}
          onClick={() => { setMetodoIsEmail(true); }}
        >
          Receber PDF por email
        </Radio.Button>
      </Radio.Group> */}

      {metodoIsEmail && (
        <Form form={form}>
          <Form.Item
            name='email'
            rules={[
              { required: true, message: 'Digite um email!' },
              { type: 'email', message: 'Email invalido!' },
            ]}
            label='Email'
          >
            <Input type='email' placeholder='seu@email.com' />
          </Form.Item>
        </Form>
      )}
      <Button type='primary' onClick={prosseguir}>
        Prosseguir
      </Button>
    </Space>
  )
}
