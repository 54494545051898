import { Box, Text } from '@chakra-ui/react'
import { Badge, Collapse, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useMemo } from 'react'
import { PendenciasResponse } from '../lib/api/apiDocumentos'
import { TabelaDocsStatusInvalidos } from './VerificarPendenciasButton'

type Salto = {
  codigo: [string, string]
  modelo: string
  serie: string
}

const TabelaSaltosNumeracao = (props: { saltos?: Array<Salto> }) => {
  const colunas: ColumnsType<Salto> = useMemo(
    () => [
      {
        title: 'Modelo',
        key: 'modelo',
        dataIndex: 'modelo',
        width: 90,
      },
      {
        title: 'Série',
        key: 'serie',
        dataIndex: 'serie',
        sorter: (a, b) => Number(a.serie) - Number(b.serie),
        width: 90,
        render: (value: number | undefined) =>
          value?.toString().padStart(3, '0'),
      },
      {
        title: 'Salto',
        key: 'codigo',
        dataIndex: 'codigo',
        render: (codigo, item) => (
          <Box>
            <Text d='inline' fontWeight='semibold'>
              {item.codigo[0]}
            </Text>{' '}
            para{' '}
            <Text d='inline' fontWeight='semibold'>
              {item.codigo[1]}
            </Text>
          </Box>
        ),
      },
    ],
    []
  )

  return (
    <Table
      size='small'
      rowKey={r => `${r.modelo}${r.serie}${r.codigo[0]}`}
      dataSource={props.saltos}
      columns={colunas}
    />
  )
}

export const PendenciasLista = ({
  pendencias,
}: {
  pendencias: PendenciasResponse
}) => {
  const temSaltos = pendencias.saltos && pendencias.saltos.length > 0
  const temStatusInvalidos = pendencias.docsStatusInvalidos.length > 0
  const temXmlAusentes = pendencias.docsXmlAusente.length > 0

  return (
    <>
      <Text mb={3} fontWeight='bold'>
        Foram encontradas as seguintes pendências no período informado:
      </Text>
      <Collapse>
        {temSaltos && (
          <Collapse.Panel
            header={
              <Typography className='flex-1'>Saltos na numeração</Typography>
            }
            className='no-padding-collapse'
            key='1'
            extra={
              <Badge overflowCount={9999} count={pendencias.saltos?.length} />
            }
            collapsible={temSaltos ? undefined : 'disabled'}
          >
            <TabelaSaltosNumeracao saltos={pendencias.saltos} />
          </Collapse.Panel>
        )}

        {temStatusInvalidos && (
          <Collapse.Panel
            header={
              <Typography className='flex-1'>
                Documentos com Status inválidos
              </Typography>
            }
            className='no-padding-collapse'
            key='2'
            extra={
              <Badge
                overflowCount={9999}
                count={pendencias.docsStatusInvalidos.length}
              />
            }
          >
            <TabelaDocsStatusInvalidos docs={pendencias.docsStatusInvalidos} />
          </Collapse.Panel>
        )}

        {temXmlAusentes && (
          <Collapse.Panel
            header={
              <Typography className='flex-1'>
                Documentos com XML ausente
              </Typography>
            }
            className='no-padding-collapse'
            key='3'
            extra={
              <Badge
                overflowCount={9999}
                count={pendencias.docsXmlAusente.length}
              />
            }
          >
            <TabelaDocsStatusInvalidos docs={pendencias.docsXmlAusente} />
          </Collapse.Panel>
        )}
      </Collapse>
    </>
  )
}
