import React, { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { BarraDocumentos } from '../../components/BarraDocumentos'
import { TabelaDocumentos } from '../../components/Tabela/TabelaDocumentos'
import { useTodosDocumentos } from '../../hooks/useTodosDocumentos'
import {
  filtroChaveState,
  filtroCodigoState,
  filtroIgnorarBaixadosState,
  filtroModeloState,
  filtroPeriodoState,
  filtroSerieState,
  filtroStatusState,
  getPeriodo,
  TipoModelo,
} from '../../store/filtrosState'
import XmlErrorInvalido from 'components/Basico/AlertaXmlInvalido'

export const ModeloDocPageComponent = (props: { modelo: TipoModelo }) => {
  const setModelo = useSetRecoilState(filtroModeloState)
  const setStatus = useSetRecoilState(filtroStatusState)
  const setPeriodo = useSetRecoilState(filtroPeriodoState)
  const setSerie = useSetRecoilState(filtroSerieState)
  const setCodigo = useSetRecoilState(filtroCodigoState)
  const setChave = useSetRecoilState(filtroChaveState)
  const setIgnorarBaixados = useSetRecoilState(filtroIgnorarBaixadosState)
  const [errorModalVisible, setErrorModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setModelo([props.modelo])
  }, [setModelo, props.modelo])

  useEffect(() => {
    setStatus([])
    setPeriodo({
      inicio: getPeriodo().primeiroDia,
      fim: getPeriodo().ultimoDia,
    })
    setSerie('')
    setCodigo('')
    setChave('')
    setIgnorarBaixados(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modelo])

  const { documentos, isLoading } = useTodosDocumentos()
  const data = documentos && documentos.data

  useEffect(() => {
    if (data && data.length > 0) {
      const hasErrorXml = data.some(xml => (xml.valor === null || xml.valor === undefined || xml.chave === null || xml.chave === undefined) && xml.status !== 'I');
      if (hasErrorXml) {
        setErrorModalVisible(true);
      }
    }
  }, [data]);

  
  return (
    <div>
      <BarraDocumentos
        valorTotal={documentos?.valorTotal}
        filtroTipo
        filtroTipoDisabled
      />
        <XmlErrorInvalido
          visible={errorModalVisible}
          onClose={() => setErrorModalVisible(false)}
          xmlData={data || []}
        />
      <TabelaDocumentos
        // colunasExtra={colunas}
        isLoading={isLoading}
        data={data}
      />
    </div>
  )
}
