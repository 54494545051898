import { message, Tooltip } from 'antd'
import React, { memo } from 'react'
import { useCopyToClipboard } from 'react-use'

export const ClickCopiar = memo((props: { texto: string }) => {
  const [, copyToClipboard] = useCopyToClipboard()

  const copiar = () => {
    copyToClipboard(props.texto)
    message.success('Chave copiada!')
  }

  return (
    <Tooltip autoAdjustOverflow placement='leftBottom' title={props.texto}>
      <span onClick={copiar} className='cursor-pointer'>
        {props.texto}
      </span>
    </Tooltip>
  )
})
