import { DownloadOutlined } from '@ant-design/icons'
import { Box, Flex, HStack, Wrap } from '@chakra-ui/react'
import { Menu, Statistic } from 'antd'
import React from 'react'
import { useMeasure } from 'react-use'
import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { useResponsivo } from '../hooks/useResponsivo'
import { totalState } from '../store/paginacaoState'
import { selecaoState } from '../store/selecaoState'
import {
  downloadSelecionadosModalState,
  downloadTodosModalState,
} from '../store/uiState'
import { DownloadButtonContainer } from './DownloadButtonContainer'
import { EsconderColunas } from './EsconderColunas'
import { FiltroModelo } from './Filtros/FiltroModelo'
import { FiltrosMobile } from './Filtros/Filtros'
import { FiltroStatus } from './Filtros/FiltroStatus'
import { SeletorMesPeriodo } from './Filtros/SeletorPeriodo'
import { VerificarPendenciasButton } from './VerificarPendenciasButton'

export const TotalDocumentos = (props: { valorTotal?: number }) => {
  const { sm } = useResponsivo()

  const total = useRecoilValue(totalState)

  return (
    <>
      <Statistic
        decimalSeparator=','
        groupSeparator='.'
        style={{ lineHeight: 1 }}
        title={sm ? 'Documentos' : 'Docs'}
        value={total}
      />
      <Statistic
        decimalSeparator=','
        groupSeparator='.'
        prefix='R$'
        style={{ lineHeight: 1, whiteSpace: 'nowrap' }}
        title={'Valor Total'}
        value={props.valorTotal ? props.valorTotal.toFixed(2) : 0} // Utilizei o toFixed(2) para alterar para duas casas decimais.
      />
    </>
  )
}

const widthBreakpoint = 1050

export const BarraDocumentos = (props: {
  filtroTipo?: boolean
  filtroTipoDisabled?: boolean
  valorTotal?: number
}) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>()

  return (
    <Flex ref={ref} p={2} justifyContent='space-between'>
      <Wrap align='center' spacing={2} shouldWrapChildren>
        {width > widthBreakpoint && <SeletorMesPeriodo />}
        {width > widthBreakpoint && <FiltroStatus />}
        {props.filtroTipo && width > widthBreakpoint && (
          <FiltroModelo disabled={props.filtroTipoDisabled} />
        )}
        <FiltrosMobile
          filtroTipo={props.filtroTipo}
          filtroTipoDisabled={props.filtroTipoDisabled}
        />

        <EsconderColunas />
      </Wrap>
      <HStack>
        <DownloadButtonContainer />

        <Box>
          <VerificarPendenciasButton />
        </Box>

        <TotalDocumentos valorTotal={props.valorTotal} />
      </HStack>
    </Flex>
  )
}

export const downloadMenuState = atom({
  key: 'downloadMenu',
  default: false,
})

export const DownloadMenu = () => {
  const toggleDownloadSelecionados = useSetRecoilState(
    downloadSelecionadosModalState
  )

  const toggleDownloadTodos = useSetRecoilState(downloadTodosModalState)

  const selecao = useRecoilValue(selecaoState)

  const total = useRecoilValue(totalState)

  const temSelecao = selecao.length > 0

  const toggleMenu = useSetRecoilState(downloadMenuState)

  return (
    <Menu onClick={() => toggleMenu(false)}>
      <Menu.Item
        key='selecionados'
        disabled={!temSelecao}
        onClick={() => toggleDownloadSelecionados(true)}
        icon={<DownloadOutlined />}
      >
        Baixar Selecionados ({selecao.length})
      </Menu.Item>
      <Menu.Item
        key='tudo'
        disabled={!total}
        onClick={() => toggleDownloadTodos(true)}
        icon={<DownloadOutlined />}
      >
        Baixar Tudo ({total})
      </Menu.Item>
    </Menu>
  )
}
