import { Button, ButtonProps, Icon, IconButton } from '@chakra-ui/react'
import React from 'react'
import { forwardRef } from 'react'
import { IconType } from 'react-icons/lib'
import { useResponsivo } from '../hooks/useResponsivo'

// export const ResponsiveButton = ({
//   icon,
//   children,
//   ...rest
// }: ButtonProps & { icon: IconType }) => {
//   const { sm } = useResponsivo()

//   return sm ? (
//     <Button {...rest} leftIcon={<Icon as={icon} />} />
//   ) : (
//     <IconButton {...rest} aria-label='icone' icon={<Icon as={icon} />} />
//   )
// }

export const ResponsiveButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & { icon: IconType }
>((props: ButtonProps & { icon: IconType }, ref) => {
  const { sm } = useResponsivo()
  const { icon, children, ...rest } = props
  return sm ? (
    <Button {...rest} ref={ref} leftIcon={<Icon as={icon} />}>
      {children}
    </Button>
  ) : (
    <IconButton
      rounded={4}
      {...rest}
      ref={ref}
      aria-label='icone'
      icon={<Icon as={icon} />}
    />
  )
})
