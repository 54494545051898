import { Input } from 'antd'
import { useSetRecoilState } from 'recoil'
import { filtroSerieState } from '../../store/filtrosState'

const { Search } = Input

export const FiltroSerie = () => {
  const setSerie = useSetRecoilState(filtroSerieState)

  const onSearch = (value: string) => setSerie(value)

  return (
    <div className='w-32'>
      <Search placeholder='Serie' allowClear onSearch={onSearch} className='' />
    </div>
  )
}
