import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  VStack,
} from '@chakra-ui/react'
import React from 'react'

export const AlertaErro = ({
  titulo,
  descricao,
  ...props
}: {
  titulo?: string
  descricao?: React.ReactNode
} & AlertProps) => {
  return (
    <Alert
      status='error'
      variant='subtle'
      justifyContent='center'
      textAlign='center'
      rounded={8}
      padding={7}
      {...props}
      flexDir='row'
    >
      <VStack>
        <AlertTitle d='flex' alignItems='center' mt={4} mb={1} fontSize='lg'>
          <AlertIcon mr={2} />
          {titulo}
        </AlertTitle>
        <AlertDescription maxWidth='container.md'>{descricao}</AlertDescription>
      </VStack>
    </Alert>
  )
}
