import { ConfigProvider, message } from 'antd'
import locale from 'antd/lib/locale/pt_BR'
import 'moment/locale/pt-br'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/ErrorFallback'
import { RoutesComponent } from './components/RoutesComponent'

if (window.location.protocol.indexOf('https') === 0) {
  var el = document.createElement('meta')
  el.setAttribute('http-equiv', 'Content-Security-Policy')
  el.setAttribute('content', 'upgrade-insecure-requests')
  document.head.append(el)
}

const App = () => {
  const onError = (e: Error) => {
    console.log(e)
    message.error(e.message)
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      <ConfigProvider locale={locale}>
        <RoutesComponent />
      </ConfigProvider>
    </ErrorBoundary>
  )
}

export default App
