import { Tag } from 'antd'
import React from 'react'
import { ModeloNotaRecord } from '../../lib/types'

const coresModelo: Record<string, string> = {
  'nfe-emitida': '#87d068',
  'nfe-terceiro': '#56638A',
  nfce: '#FDC835',
  'cte-emitida': 'brown',
  'cte-terceiro': '#53F4FF',
  mdfe: '#4F6367',
}

export const getLabelFromModelo = (s?: string | null) =>
  s ? ModeloNotaRecord[s] : 'Sem Modelo'

export const TagModelo = (props: { modelo: string }) => {
  const tipoNota = ModeloNotaRecord[props.modelo]

  const cor = coresModelo[props.modelo]

  return <Tag color={cor}>{tipoNota}</Tag>
}
