import { useEffect } from 'react'
import { usePaginacao } from './useFiltros'

export const useCheckTotal = ({
  isLoading,
  isValidating,
  total,
}: {
  isLoading: boolean
  isValidating: boolean
  total: number
}) => {
  const { setTotal } = usePaginacao()

  useEffect(() => {
    if (!isLoading && !isValidating) {
      setTotal(total)
    }
  }, [total, setTotal, isLoading, isValidating])
}
