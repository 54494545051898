import { Button, Modal, Tooltip } from 'antd'
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React from 'react'
import {
  DateRangePicker,
  defaultInputRanges,
  Range,
  StaticRange,
} from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { useBoolean } from 'react-use'
import { useRecoilState } from 'recoil'
import { filtroPeriodoState } from '../../store/filtrosState'

const staticRangeHandler: StaticRange = {
  label: '',
  range: () => ({ starteDate: new Date(), endDate: new Date() }),
  isSelected(range: Range) {
    const definedRange = this.range && this.range({})
    return (
      isSameDay(
        range.startDate || new Date(),
        definedRange?.startDate || new Date()
      ) &&
      isSameDay(
        range.endDate || new Date(),
        definedRange?.endDate || new Date()
      )
    )
  },
}

function createStaticRanges(ranges: Partial<StaticRange>[]): StaticRange[] {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }))
}

const staticRanges = createStaticRanges([
  {
    label: 'Hoje',
    range: () => ({
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
    }),
  },
  {
    label: 'Ontem',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -1)),
      endDate: endOfDay(addDays(new Date(), -1)),
    }),
  },

  {
    label: 'Essa semana',
    range: () => ({
      startDate: startOfWeek(new Date()),
      endDate: endOfWeek(new Date()),
    }),
  },
  {
    label: 'Semana passada',
    range: () => ({
      startDate: startOfWeek(addDays(new Date(), -7)),
      endDate: endOfWeek(addDays(new Date(), -7)),
    }),
  },
  {
    label: 'Esse mês',
    range: () => ({
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    }),
  },
  {
    label: 'Mês passado',
    range: () => ({
      startDate: startOfMonth(addMonths(new Date(), -1)),
      endDate: endOfMonth(addMonths(new Date(), -1)),
    }),
  },
])

// const oldInputRanges = defaultInputRanges.map((range, i) => ({
//   ...range,
//   label: i === 0 ? 'Dias até hoje' : 'Dias a partir de hoje',
// }))

const inputRanges = [{ ...defaultInputRanges[0], label: 'Ultimos dias' }]

export const NovoSeletorPeriodo = () => {
  const [{ inicio, fim }, setPeriodo] = useRecoilState(filtroPeriodoState)

  const onChange = (item: any) => {
    const selection = item.range1 as Range
    setPeriodo({
      inicio: selection.startDate || new Date(),
      fim: selection.endDate || new Date(),
    })
  }

  const [isOpen, toggle] = useBoolean(false)

  return (
    <div>
      <Tooltip title='Mudar data'>
        <Button className='md:w-64' onClick={() => toggle(true)}>
          {format(inicio, 'dd/MM/yyyy')} &nbsp; até &nbsp;
          {format(fim, 'dd/MM/yyyy')}
        </Button>
      </Tooltip>
      <Modal
        title='Selecione o periodo'
        width={890}
        bodyStyle={{ padding: 0 }}
        visible={isOpen}
        onCancel={() => toggle(false)}
        footer={
          <div className='text-right'>
            <Button type='primary' onClick={() => toggle(false)}>
              Fechar
            </Button>
          </div>
        }
      >
        <DateRangePicker
          staticRanges={staticRanges}
          inputRanges={inputRanges}
          locale={ptBR}
          onChange={onChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          dateDisplayFormat='dd MMM, yyyy'
          ranges={[{ startDate: inicio, endDate: fim }]}
          direction='horizontal'
        />
      </Modal>
    </div>
  )
}
