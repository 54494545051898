import { Box } from '@chakra-ui/react'
import { CheckCliente } from 'components/Auth/CheckCliente/CheckCliente'
import { CheckUser } from 'components/Auth/CheckUser'
import { AppShell } from 'components/Basico/AppShell'
import { CheckContador } from 'components/Contador/CheckContador'
import { CheckUsuarioPadraoContador } from 'components/Contador/CheckUsuarioPadraoContador'
import React from 'react'
import { Outlet } from 'react-router'

export const Dashboard = () => {
  return (
    <CheckContador>
      <CheckUsuarioPadraoContador>
        <CheckCliente>
          <CheckUser>
            <AppShell>
              <Box h='full' w='full' py={1} px={1}>
                <Outlet />
              </Box>
            </AppShell>
          </CheckUser>
        </CheckCliente>
      </CheckUsuarioPadraoContador>
    </CheckContador>
  )
}
